<template>
  <div class="main-container-config">
    <div>
      <form-render :fields.sync="fields" :form.sync="myForm" :key="keyFormRender" class="ml-1" :disableAll="disabledAllFields">
        <template #mybuttons>
          <b-dropdown :disabled="disabledAllFields" variant="link" right toggle-class="text-decoration-none" no-caret class="drodown-custom z-index">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="23" class="color-options"/>
            </template>
            <b-dropdown-item v-for="(option, index) in optionsLevels" :key="index"  @click="handleItemClick(option, myForm)" >
              <span v-if="!option.disabled">{{ option.text }}</span> 
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </form-render>
    </div>
    <ul v-if="hasSubLevels && depth > 0" style="list-style: none" class="mb-0">
      <li v-for="(subValue, subKey) in myForm.value" :key="subKey" :class="`child-selector-${myForm.id}-is-modal-${!!isModal}`">
        <selector-configuration :isModal="isModal" ref="selectorConfig" @config="updateSetting" @remove="removeSetting" @create="createSubLevel" :index="subKey" :parentIndex="index" :form="subValue" :options="options" :depth="depth - 1" :errorFields="errorFields" :maxDepth="maxDepth" :disabledAllFields="disabledAllFields"/>
      </li>
    </ul>
    <div v-if="depth === 0 && [this.CONDITIONS.OBJECT, this.CONDITIONS.ARRAY].includes(myForm.type.text)" class="ml-1 pb-1">
      <p class="text-aling-center"><strong>...</strong></p>
      <small><strong>El máximo de niveles es {{ maxDepth }}, cambie el "Modo Ingreso" a JSON en caso de exceder el máximo</strong></small>
    </div>
  </div>
</template>

<script>
import SelectorConfiguration from './SelectorConfiguration.vue'
export default {
  name: 'SelectorConfiguration',
  components: { SelectorConfiguration },
  props: ['form', 'options', 'depth', 'index', 'parentIndex', 'errorFields', 'maxDepth', 'disabledAllFields', 'isModal'],
  data () {
    return {
      myForm: {
        type: ''
      },
      fields: [],
      keyFormRender: 0,
      valueTypeOptions: [
        {id: 5, text: 'Booleano'},
        {id: 1, text:'Dinámico'},
        {id: 6, text: 'Matriz'},
        {id: 7, text: 'Nulo'},
        {id: 2, text: 'Número'},
        {id: 3, text: 'Sub Nivel'},
        {id: 4, text: 'Valor Fijo'}
      ],
      CONDITIONS: Object.freeze({
        STRING: 'Valor Fijo',
        DYNAMIC: 'Dinámico',
        OBJECT: 'Sub Nivel',
        BOOLEAN: 'Booleano',
        NUMBER: 'Número',
        ARRAY: 'Matriz'
      }),
      optionsLevels: [
        { text: 'Eliminar', value: 'delete' }
      ],
      optionsBoolean: [
        {id: true, text:'True'},
        {id: false, text: 'False'}
      ],
      hasSubLevels: false
    }
  },
  watch: {
    form: {
      handler () {
        this.originalForm = {...this.form}
        this.myForm = {...this.form}
        if (this.myForm) {
          if (this.myForm.type?.text === this.CONDITIONS.DYNAMIC) this.formatterOptions()
          if (this.myForm.type?.text === this.CONDITIONS.BOOLEAN) this.formatterOptionsBoolean()
          this.updateHasSubLevels()
        }
      },
      deep: true
    },
    errorFields: {
      handler (val) {
        if (!!val) this.setErrorField()
      },
      deep: true
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.myForm = {...this.form}
      this.originalForm = {...this.form} 
      this.setField()
      if (this.myForm) {
        this.setType('type', this.myForm.type, true)
        this.updateHasSubLevels()
      } 
      if (this.errorFields) this.setErrorField()
    },
    setField () {
      this.fields = [
        {fieldType: 'FieldInput', name: 'key', label: this.generateLabel(), noTranslate: true,  containerClass: this.myForm.isChildrenArray ? 'hide' : 'col-md-4 container-info', placeholder: 'Ingrese campo', change: () => this.updateSetting(this.myForm.key, 'key', this.originalForm, 200)},
        {fieldType: 'FieldSelect', name: 'type', label: 'Tipo', options: this.setOptionsType(), containerClass: 'col-md-3 container-info', change: this.changeType},
        { name: 'mybuttons', useSlot: true, containerClass: 'col container-info custom-dropdown' }
      ] 
    },
    setOptionsType () {
      return this.depth > 0 ? this.valueTypeOptions : this.valueTypeOptions.filter(option => ![this.CONDITIONS.OBJECT, this.CONDITIONS.ARRAY].includes(option.text))
    },
    updateSetting (form, field, original, time, type, formatterForm) {
      let newForm = form
      if (formatterForm === this.CONDITIONS.BOOLEAN && !!form) newForm = form.id
      this.$debounce('selector-configuration', () => {
        this.$emit('config', newForm, field, original, time, type)
      }, time)
    },
    changeType (name, value) {
      this.setType(name, value)
      const default_value = [this.CONDITIONS.OBJECT, this.CONDITIONS.ARRAY].includes(value?.text) ? [] : value?.text === this.CONDITIONS.STRING ? '' : null
      this.myForm.value = default_value
      this.updateSetting(this.myForm, null, this.originalForm, 0)
    },
    setType (name, value, notClean) {
      this.myForm.type = value
      this.indexType = this.getPositionFindIndex(this.fields, 'name', 'type')
      this.indexSelectValue = this.getPositionFindIndex(this.fields, 'name', 'value')
      this.optionsLevels = [
        { text: 'Eliminar', value: 'delete' }
      ]
      if (!notClean) this.myForm.value = ''
      if (!value) {
        if (this.indexSelectValue !== -1) this.fields.splice(this.indexSelectValue, 1)
        return 
      } 
      switch (value.id) {
      case 1: {
        this.handleDynamicType()
        break
      }
      case 2: {
        this.handleNumberType()
        break
      }
      case 3: {
        this.handleObjectType()
        break
      }
      case 4: {
        this.handleStringType()
        break
      }
      case 5: {
        this.handleBooleanType()
        break
      }
      case 6: {
        this.handleArrayType()
        break
      }
      case 7: {
        this.handleNullType()
        break
      }
      default: {
        this.optionsLevels = [
          { text: 'Eliminar', value: 'delete' }
        ]
        break
      }
      }
      this.keyFormRender++
    },
    handleDynamicType () {
      const inputIndex = this.fields.findIndex(field => field.name === 'value' && ['number', 'input', 'boolean'].includes(field.typeInfo))
      if (inputIndex !== -1) {
        this.fields.splice(inputIndex, 1)
      }
      const newIndex = inputIndex !== -1 ? inputIndex : this.indexType + 1
      this.fields.splice(newIndex, 0, {
        fieldType: 'FieldSelect',
        name: 'value',
        label: 'Información correspondiente',
        options: this.options,
        containerClass: 'col-md-4 container-info',
        typeInfo: 'select',
        change: () => this.updateSetting(this.myForm?.value?.value || null, 'value', this.originalForm)
      })
      this.formatterOptions()
    },
    handleStringType () {
      const selectIndex = this.fields.findIndex(field => field.name === 'value' && ['number', 'select', 'boolean'].includes(field.typeInfo))
      if (selectIndex !== -1) {
        this.fields.splice(selectIndex, 1)
      }
      const newIndex = selectIndex !== -1 ? selectIndex : this.indexType + 1
      this.fields.splice(newIndex, 0, {
        fieldType: 'FieldInput',
        name: 'value',
        label: 'Información correspondiente',
        containerClass: 'col-md-4 container-info',
        typeInfo: 'input',
        change: () => this.updateSetting(this.myForm.value, 'value', this.originalForm, 200)
      })
    },
    handleBooleanType () {
      const selectIndex = this.fields.findIndex(field => field.name === 'value' && ['number', 'select', 'input'].includes(field.typeInfo))
      if (selectIndex !== -1) {
        this.fields.splice(selectIndex, 1)
      }
      const newIndex = selectIndex !== -1 ? selectIndex : this.indexType + 1
      this.fields.splice(newIndex, 0, {
        fieldType: 'FieldSelect',
        name: 'value',
        label: 'Información correspondiente',
        options: this.optionsBoolean,
        containerClass: 'col-md-4 container-info',
        typeInfo: 'boolean',
        change: () => this.updateSetting(this.myForm?.value || null, 'value', this.originalForm, 0, null, this.CONDITIONS.BOOLEAN)
      })
      this.formatterOptionsBoolean()
      
    },
    handleNumberType () {
      const selectIndex = this.fields.findIndex(field => field.name === 'value' && ['boolean', 'select', 'input'].includes(field.typeInfo))
      if (selectIndex !== -1) {
        this.fields.splice(selectIndex, 1)
      }
      const newIndex = selectIndex !== -1 ? selectIndex : this.indexType + 1
      this.fields.splice(newIndex, 0, {
        fieldType: 'FieldInput',
        name: 'value',
        label: 'Información correspondiente',
        containerClass: 'col-md-4 container-info',
        typeInfo: 'number',
        type: 'number',
        change: () => this.updateSetting(Number(this.myForm.value), 'value', this.originalForm, 200)
      })
    },
    handleObjectType () {
      this.optionsLevels = this.depth > 0 ? [{ text: 'Añadir sub nivel', value: 'add' }, { text: 'Eliminar', value: 'delete' }] : [{ text: 'Eliminar', value: 'delete' }]
      if (this.indexSelectValue !== -1) {
        this.fields.splice(this.indexSelectValue, 1)
      }
    },
    handleArrayType () {
      this.optionsLevels = this.depth > 0 ? [{ text: 'Añadir elemento', value: 'add' }, { text: 'Eliminar', value: 'delete' }] : [{ text: 'Eliminar', value: 'delete' }]
      if (this.indexSelectValue !== -1) {
        this.fields.splice(this.indexSelectValue, 1)
      }
    },
    handleNullType () {
      this.optionsLevels = [{ text: 'Eliminar', value: 'delete' }]
      if (this.indexSelectValue !== -1) {
        this.fields.splice(this.indexSelectValue, 1)
      }
    },
    formatterOptions () {
      const formatterValue = this.options.filter(option => option.value === this.myForm.value)
      this.myForm.value = formatterValue[0]
    },
    formatterOptionsBoolean () {
      const formatterValue = this.optionsBoolean.filter(option => option.id === this.myForm.value)
      this.myForm.value = formatterValue[0]
    },
    updateHasSubLevels() {
      if (Array.isArray(this.myForm.value)) {
        this.hasSubLevels = true
      } else {
        this.hasSubLevels = false
      }
    },
    getPositionFindIndex (origin, type,  name) {
      return origin.findIndex(el => el[type] === name)
    },
    handleItemClick(option, myForm) {
      if (option.value === 'add') {
        this.createSubLevel(myForm)
      }
      if (option.value === 'delete') {
        this.removeSetting(myForm)
      }
    },
    removeSetting(myForm) {
      this.$emit('remove', myForm)
    },
    createSubLevel (myForm) {
      this.$emit('create', myForm)
    },
    generateLabel() {
      const getParentIndex = (component, index = []) => {
        if (component.parentIndex || component.parentIndex === 0) {
          index.unshift(component.parentIndex)
        }
        if (component.$parent) {
          return getParentIndex(component.$parent, index)
        }
        return index
      }
      const buildLabel = (parentIndex, currentIndex) => {
        let label = 'Campo '
        label += parentIndex.map(index => index + 1).join('.')

        if (currentIndex || currentIndex === 0) {
          if (label === 'Campo ') {
            label += `${currentIndex + 1}`
          } else {
            label += `.${currentIndex + 1}`
          }   
        }
        return label
      }
      const parentIndex = getParentIndex(this)
      return buildLabel(parentIndex, this.index)
    },
    setErrorField () {
      Object.entries(this.errorFields).forEach(([key, value]) => {
        const formValue = typeof this.myForm.value === 'object' ? this.myForm.value?.value : this.myForm.value
        if (this.myForm.key === key && formValue === value) {
          this.fields.map(field => {
            if (['key', 'value'].includes(field.name)) field.containerClass = 'col-md-4 container-info invalid-field'
          })
        }
        if (this.myForm.key === '' && key === '') {
          this.fields.map(field => {
            if (['key'].includes(field.name)) field.containerClass = 'col-md-4 container-info invalid-field'
          })
        }
      })  
    }
  }
}
</script>

<style >
.border-left {
  border-left: 1px solid black;
}
.main-container-config {
  border-left: 1px solid var(--secondary-3);
}
.custom-dropdown{
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-top: 10px;
}
.custom-dropdown button{
  padding: 0 !important;
}
.z-index ul {
  z-index: 11;
}
.invalid-field input[type="text"], .invalid-field .vs__dropdown-toggle {
  border-color: #ea5455;
}
.text-aling-center {
  text-align: center;
}
</style>