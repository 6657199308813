<template>
  <div class="container-customize">
    <section class="section-customize col-12 mb-1">
      <h3 class="pr-1 mb-0">Cuerpo correo devoluciones</h3>
    </section>
    <section class="section-customize">
      <p class="col-8"><b>Cuerpo correo devoluciones</b></p>
      <b-button variant="success" size="sm" @click="openModal('modalKeyWords')">Palabras claves</b-button>
      <form-render class="col-12" :fields="fields" containerButtonsClass="col-sm-12" :form.sync="form" ref="formMailTemplate" :key="keyForMailTemplate" />      
    </section>
    <div class="px-1">
      <b-button @click="updateMailTemplate" variant="warning" :disabled="loading">
        <b-spinner v-show="loading" small></b-spinner> Guardar
      </b-button>
    </div>    
    <ModalKeyWords ref="modalKeyWords" @updateForm="updateEmailBody"></ModalKeyWords>
  </div>
</template>
<script>
import ModalKeyWords from './ModalKeyWords.vue'
export default {
  props: ['companyInformation'],
  components: {
    ModalKeyWords
  },
  data() {
    return {
      fields: [],
      form: {},
      keyForMailTemplate: 0,
      loading: false
    }
  },
  methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldTextarea', name: 'mail_template', containerClass: 'col-12 container-info', rows: 10 }
      ]
      this.form.mail_template = this.companyInformation?.company?.return_mail_template
    },
    openModal(nameId) {
      this.$bvModal.show(nameId)
    },
    updateEmailBody(newVal) {
      this.form.mail_template = `${this.form.mail_template ? this.form.mail_template : ''} ${newVal}`
      this.keyForMailTemplate++
    },
    updateMailTemplate() {
      this.loading = true
      const params = {company_id: this.$route.params?.id}
      const queryParams = {mail_template: this.form.mail_template || ''}
      this.$store.dispatch('fetchService', {
        name: 'updateMailTemplate',
        params,
        queryParams,
        onSuccess: (d) => {
          this.$bvToast.toast('Formato de correo actualizado', {
            title: this.$t('Operación realizada con Exito'),
            variant: 'success',
            solid: true
          })
          this.loading = false
        },
        onError: (e) => {
          this.loading = false
          console.error(e)
        }
      }
      )
    }
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    companyInformation() {
      this.form.mail_template = this.companyInformation?.company?.return_mail_template
    }
  }
}
</script>
<style scoped>
.container-customize {
  display: grid;
  grid-template-rows: auto;
}

.section-customize {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.section-customize button {
  margin-left: auto;
  padding: 0.3rem;
  margin-right: 1rem;
}
</style>