<template>
  <div>
    <div v-if="loadingSpinner" class="loading-spinner-edit">
      <b-spinner class="custom-spinner"/>
    </div>
    <b-card>
      <div class="row align-items-center justify-content-between w-100">
          <div class="d-flex align-items-center col-md-9">
            <feather-icon
              class="icon-hover mr-2"
              icon="ChevronLeftIcon"
              size="24"
              @click="returnWebhooks()"
            />
            <img class="mr-customize" :src="require(`@/assets/images/webhooks/webhook.svg`)" alt="webhook">
            <h1 class="mb-0" v-if="!editing"><strong>{{ form.name_webhook }}</strong></h1>
            <div v-else class="d-flex flex-column w-100 mb-customize">
              <label>Nombre</label>
              <b-form-input class="customize-input" v-model="form.name_webhook" type="text"/>
              <span :class="!!form.name_webhook ? 'invalid-feedback' : 'invalid-feedback d-block'"> Este campo es requerido </span>
            </div>
          </div> 
        <b-form-checkbox
            :disabled="!activeWebhook || disabledEdit"
            switch
            class="checkbox-switch switch-webhook col-md-1 pr-0 d-flex justify-content-end"
            v-model="form.activated"
            @change="confirmChangeStatus()">
            <span class="personalize-status">{{ !!form.activated ? 'Activo' : 'Inactivo' }}</span>
        </b-form-checkbox>
      </div>
      <div class="row justify-content-between p-1 custom-container">
        <div class="col-md-12">
          <step1 
            ref="formRenderStep1" 
            @formStep="setForm" 
            :form="form" 
            :editWebhook="{webhook}"/>
        </div>
      </div>
      <div class="container-grid">
        <div class="steps row justify-content-end p-1 custom-container mr-customize container-cards">
          <div v-if="showActions">
            <b-button 
              class="container-btn-actions border-blue mb-1"
              v-if="showEditSteps"
              variant="outline-primary"
              @click="handleBtnsActions(false)">
              <feather-icon
                v-b-tooltip.hover 
                title="Editar"
                ref="editIcon"
                icon="Edit2Icon"
                size="20"
                class="text-primary"
              />
            </b-button>
            <b-button 
              class="container-btn-actions border-danger mb-1 mr-1"
              v-if="!showEditSteps"
              variant="outline-primary"
              @click="setOriginForm(true)">
              <feather-icon
                v-b-tooltip.hover 
                title="Descartar cambios"
                ref="closeIcon"
                icon="XIcon"
                size="20"
                class="text-danger"               
              />
            </b-button>
            <b-button 
              class="container-btn-actions border-success mb-1 text-success"
              v-if="!showEditSteps"
              variant="outline-primary"
              @click="validateFormEdit"
              :disabled="loading.edit"
            >
            <b-spinner small v-if="loading.edit"/>
              <feather-icon
                v-else
                v-b-tooltip.hover 
                title="Guardar cambios"
                ref="saveIcon"
                icon="SaveIcon"
                size="20"
              />
            </b-button>
          </div>
          <div v-for="(step, index) in steps" :key="index" class="col-md-12 card card-customize-edit">
            <header-step 
              :step="step" 
              :showStep="null" 
              :form="form" 
              @formStep="setForm" 
              @renderStep="renderStep"
              :messageResponse="messageResponse" 
              :errorFields="errorFields"/>
            <component 
              :key="onlyRenderStep4(step)" 
              :ref="`formRenderStep${step.id}`"
              class="mt-2" 
              :is="computedComponent[index]" 
              @formStep="setForm" 
              :form="form" 
              :messageResponse="messageResponse" 
              :errorFields="errorFields"
              @invalidJSON="invalidJSON"
              :emptyJSON="emptyJSON"
              :editWebhook="{webhook}"
              :errorURL="errorURL"/>
              <div class="mb-1 container-message" v-if="step.id === 4 && (messageResponse || showErrorJSON || emptyJSON) && hideMessageAlert">
                <div v-if="messageResponse && !showErrorJSON && !emptyJSON">
                  <feather-icon
                    :icon="messageResponse.code === 200 ? 'CheckCircleIcon' : 'XCircleIcon'"
                    size="15"
                    :class="messageResponse.code === 200 ? 'text-success' :'text-danger'" 
                    class="mr-customize"
                  />
                <span>{{ messageResponse.message }}</span>
                </div>
                <div v-if="showErrorJSON || emptyJSON">
                  <feather-icon
                    icon="XCircleIcon"
                    size="15"
                    class="text-danger mr-customize"
                  />
                  <span>¡JSON inválido! Por favor verifique los campos.</span>
                </div>
              </div>
          </div>
        </div>
        <div class="step-5 p-1 custom-container container-cards ml-customize">
          <div>
            <header-step 
              :step="{id: 5, title: 'Prueba.'}" 
              :showStep="null" 
              :form="form"
              @changeStyleContainer="changeStyleContainer"
              @formStep="setForm"
              @renderStep="renderStep"/>
          </div>
          <div class="container-center" :class="!styleContainerStep5 ? 'aling-step5' : 'aling-step5-response-test'">
            <step5 
              :key="step5Key" 
              ref="formRenderStep5" 
              :editWebhook="{webhook}"
              :form="form"
              @changeStyleContainer="changeStyleContainer" 
              @formStep="setForm"
              :setStep="setStep"/>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import HeaderStep from './components/headerStep/Header.vue'
import Step1 from './components/step/Step1.vue'
import Step2 from './components/step/Step2.vue'
import Step3 from './components/step/Step3.vue'
import Step4 from './components/step/Step4.vue'
import Step5 from './components/step/Step5.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BaseServices from '@/store/services/index'
import Swal from 'sweetalert2'
export default {
  name: 'edit-webhook',
  components: { Step1, Step2, Step3, Step4, Step5, HeaderStep },
  props: ['webhook'],
  data () {
    return {
      edit: null,
      loadingSpinner: true,
      form: {},
      steps: [
        {id: 2, title: 'Eventos que activan el Webhook.'},
        {id: 3, title: 'Endpoint del Webhook.'},
        {id: 4, title: 'Contenido del Webhook.'}
      ],
      editing: false,
      step4Key: 0,
      step5Key: 0,
      defaultKey: 0,
      loading: {
        edit: false
      },
      messageResponse: null,
      errorFields: null,
      showErrorJSON: false,
      emptyJSON: false,
      hideMessageAlert: false,
      activeWebhook: false,
      disabledEdit: false,
      errorURL: false,
      originalForm: null,
      baseService: new BaseServices(this),
      isOriginalForm: false,
      styleContainerStep5: false,
      setStep: false,
      showEditSteps: true,
      disabledSteps: true,
      showActions: false
    }
  },
  watch: {
    form: {
      handler (val, oldVal) {
        if (val && this.originalForm) {
          // Bloqueo de boton guadar cambios
          if (val.name_webhook === this.originalForm.name_webhook 
              && JSON.stringify(val.types) === JSON.stringify(this.originalForm.types) 
              && JSON.stringify(val.filtersNew) === JSON.stringify(this.originalForm.filters)
              && val.endpoint === this.originalForm.endpoint
              && JSON.stringify(val.methods) === JSON.stringify(this.originalForm.methods) 
              && JSON.stringify(val.headersArray) === JSON.stringify(this.originalForm.headersArrayEdit) 
              && JSON.stringify(val.json) === JSON.stringify(this.originalForm.jsonEdit) 
              && val.jsonStringify === this.originalForm.jsonStringifyEdit
          ) 
          {
            this.isOriginalForm = true
          } 
          else 
          {
            this.isOriginalForm = false
          }
        }
        
      },
      deep: true
    },
    types_webhooks () {
      if (this.webhook?.response_create) {
        if (this.webhook.response_create.webhook.method !== 'GET') {
          this.messageResponse = {
            message: '¡Validación de campos Ok!',
            code: 200
          }
        }
        this.setWebhook(this.webhook?.response_create, 'create')
        this.form.activated = false
        this.loadingSpinner = false
        this.$success(this.$t('msg-exito-crear-webhook'))
        this.showActions = true
      } else {
        this.getWebhook()
      }
    }
  },
  computed: {
    computedComponent() {
      return this.steps.map((step) => {
        return `Step${step.id}`
      })
    },
    ...mapGetters({
      types_webhooks: 'getTypeWebhook'
    })
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.edit = !!this.webhook?.id
      this.disabledEdit = this.webhook?.disabledEdit
      this.webhook.disabledSteps = true
      this.getTypesWebhooks()
    },
    getTypesWebhooks () {
      const params = {}
      const queryParams = {}
      this.$store.dispatch('fetchService', { name: 'getTypeWebhook', params, queryParams})  
    },
    setWebhook (value, origin) {
      const webhook = origin === 'create' ? value.webhook : value
      this.activeWebhook = webhook.tested
      const filterType = this.types_webhooks.filter(type => type.id === webhook.resource_webhook_id)[0]
      const statusSaved = webhook.filters ? webhook.filters['status.status_id'] : []
      const formatterType = {
        ...filterType,
        default_payload: webhook.payload || filterType.default_payload,
        default_headers: this.convertHeadersToObject(webhook.headers),
        setObtainedStates: true
      }
      const payload = {
        name_webhook: webhook.description,
        methods: {id: webhook.method, text: webhook.method},
        endpoint: webhook.url,
        types: formatterType,
        filters: statusSaved,
        id: webhook.id,
        type: { id: 1, text: this.$t('Selectores'), name: 'selectors', disabled: false}
      }
      this.form = payload
      this.originalForm = {
        ...payload,
        headersArrayEdit: formatterType.default_headers,
        jsonEdit: webhook.payload ? this.jsonToArray(webhook.payload) : this.jsonToArray(filterType.default_payload),
        jsonStringifyEdit: webhook.payload ? JSON.stringify(webhook.payload, null, 2) :  JSON.stringify(filterType.default_payload, null, 2)
      }
    },
    getWebhook () {
      this.loadingSpinner = true
      const params = {}
      const queryParams = {
        webhook_id: this.webhook.id
      }
      this.$store.dispatch('fetchService', { name: 'getWebhookID', params, queryParams, onSuccess: (data) => this.onSuccessGetWebhook(data)})
    },
    onSuccessGetWebhook (data) {
      const setWebhook = {
        ...data.response,
        payload: data.response.payload_data
      }
      this.setWebhook(setWebhook, 'edit')
      this.form.activated = this.webhook.isActive
      this.loadingSpinner = false
      if (this.disabledEdit) {
        this.$warning('Estas viendo un webhook de tu MKP, solo tiene habilitado la visualización.', () => null, 'Aviso', 'Aceptar', 'btn btn-customyesno-success')
        this.showActions = false
      } else {
        this.showActions = true
      }
    },
    convertHeadersToObject(headers) {
      if (!headers || typeof headers !== 'object') {
        return [{key: '', value: ''}]
      }
      return Object.keys(headers).map(key => {
        return {
          key, 
          value: typeof headers[key] === 'object' && headers[key] !== null ? JSON.stringify(headers[key]) : headers[key]
        }
      })
    },
    returnWebhooks () {
      if (this.isOriginalForm) {
        this.emitReturnList()
      }
      else {
        this.confirmReturn()
      }
      
    },
    emitReturnList () {
      this.$emit('handleComponentDisplay', 'list')
    },
    confirmReturn () {
      this.$confirm(this.$t('msg-pregunta-retornar-webhook'), () => this.emitReturnList())
    },
    onlyRenderStep4 (step) {
      return step.id === 4 ? this.step4Key : this.defaultKey
    },
    renderStep (value, step) {
      if (value && !step) this.step4Key++
      if (value && step === 5) this.step5Key++
      this.setStep = value && step === 5

    },
    invalidJSON (value) {
      this.showErrorJSON = value
    },
    setForm (data) {
      this.form = {
        ...this.form,
        ...data
      }
      this.hideMessageAlert = this.form?.methods && this.form.methods.text !== 'GET'
      if (this.form.statusAll) {
        const statusSelected = this.form.statusAll.filter(status => !!status.saved)
        const statusIDs = statusSelected.map(status => status.id)
        this.form.filtersNew = statusIDs
      }
    },
    confirmChangeStatus () {
      Swal.fire({
        width: 'default',
        icon: 'warning',
        title: 'Confirmar',
        html: this.$t('msg-pregunta-actualizar-webhook', {status: this.webhook.isActive ? 'Inactivo' : 'Activo'}),
        allowOutsideClick: true,
        focusConfirm: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-customyesno-success',
          cancelButton: 'btn btn-customyesno-danger mr-1'
        },
        buttonsStyling: false 
      })
        .then(res => {
          if (res.isConfirmed) {
            this.changeStatus()
          }
          if (res.isDismissed) {
            this.form.activated = this.webhook.isActive
          }
        })
    },
    changeStatus () {
      const params = {}
      const queryParams = {
        webhook_id: this.webhook.id,
        organization_id: this.webhook.organization_id,
        shipper_id: this.webhook.owner && this.webhook.owner?.id ? this.webhook.owner.id : this.webhook.shipper_id
      }
      return this.baseService.callService('toggleActiveWebhook', queryParams, params)
        .then(resp => {
          this.$success(this.$t('msg-exito-actualizar-webhook-estado'))
          this.webhook.isActive = this.form.activated
        })
        .catch(err => {
          this.form.activated = this.webhook.isActive
          this.$alert(this.$t('msg-problema-actualizar', {code: err}))
        })
    },
    setTypeValue (value, obj) {
      switch (typeof value) {
      case 'string':
        if (value.startsWith('<') && value.endsWith('>')) {
          const filterDynamic = this.form.types.selectors.filter(option => option.value === value)
          obj.type = filterDynamic.length > 0 ? {id: 1, text:'Dinámico'} : {id: 4, text: 'Valor Fijo'}
        } else {
          obj.type = {id: 4, text: 'Valor Fijo'}
        }
        break
      case 'number':
        obj.type = {id: 2, text: 'Número'}
        break
      case 'boolean':
        obj.type = {id: 5, text: 'Booleano'}
        break
      case 'object':
        if (!!Array.isArray(value)) {
          obj.type = {id: 6, text: 'Matriz'}
        } 
        else if (value === null) {
          obj.type = {id: 7, text: 'Nulo'}
        } else {
          obj.type = {id: 3, text: 'Sub Nivel'}
        }
        break
      default:
        obj.type = {id: 7, text: 'Nulo'}
        break
      }
    },
    jsonToArray(jsonData, parentId = '', typeParent) {
      if (jsonData) {
        const objects = []
        Object.entries(jsonData).forEach(([key, value], index) => {
          const id = parentId ? `${parentId}_${index + 1}` : `${index + 1}`
          const obj = {
            id,
            key
          }
          this.setTypeValue(value, obj)
          if (typeParent && typeParent.id === 6) obj.isChildrenArray = true
          if (typeof value === 'object' && value !== null) {
            obj.value = this.jsonToArray(value, id, obj.type)
          } else {
            obj.value = value
          }
          objects.push(obj)
        })
        return objects
      }
    },
    arrayToJson(arrayData, typeParent = null) {
      const jsonData = typeParent && typeParent.id === 6  ? [] : {}
      arrayData.forEach(obj => {
        const { id, key, value, type, isChildrenArray } = obj
        if (Array.isArray(value)) {
          const childJson = this.arrayToJson(value, type)
          if (typeParent && typeParent.id === 6) {
            jsonData.push(childJson)
          } else {
            jsonData[key] = childJson
          }
        } 
        else if (!!isChildrenArray) {
          jsonData.push(value)  
        } 
        else {
          jsonData[key] = value
        }
      })
      
      return jsonData
    },
    setOriginForm (value) {
      if (this.isOriginalForm) {
        this.handleBtnsActions(value)
      }
      else {
        this.confirmSetOriginForm(value)
      }
    },
    confirmSetOriginForm (value) {
      this.$confirm(this.$t('msg-pregunta-cancelar-edicion-webhook'), () => this.handleBtnsActions(value, true), 'Advertencia')
    },
    handleBtnsActions (value, setForm) {
      this.showEditSteps = value
      this.webhook.disabledSteps = value
      this.form.disabledSteps = value
      this.editing = !value
      this.$nextTick(() => {
        if (setForm) {
          const statusAll = this.form.statusAll.map(status => {
            return {
              ...status,
              saved: this.originalForm.filters.includes(status.id)
            }
          })
          this.form = {
            ...this.form,
            endpoint: this.originalForm.endpoint,
            filtersNew: this.originalForm.filters,
            headersArray: this.originalForm.headersArrayEdit,
            json: this.originalForm.jsonEdit,
            jsonStringify: this.originalForm.jsonStringifyEdit,
            methods: this.originalForm.methods,
            name_webhook: this.originalForm.name_webhook,
            type: this.originalForm.type,
            types: this.originalForm.types,
            counterStatus: {
              ...this.form.counterStatus,
              counter: this.originalForm.filters.length 
            },
            statusAll,
            disabledSteps: value

          }
          this.step4Key++
        }
      })
    },
    // Si es un JSON valido deja crear, sino sale alerta
    validateJSON () {
      if (this.form?.methods && this.form?.type && (this.form?.json || this.form?.jsonStringify)) {
        if (this.form.methods?.text !== 'GET') {
          if (this.form.type?.id === 1 && !(this.form.json[0].key === '' && this.form.json[0].value === null && this.form.json.length === 1)) return true
          else if (this.form.type?.id === 2 && this.form.jsonStringify) return true
          else return false
        } 
        else return true
      } else return false  
    },
    formatDefaultJson () {
      if (this.form.type.id === 1) {
        return this.arrayToJson(this.form.json)
      } else {
        return  JSON.parse(this.form.jsonStringify)
      }
    },
    async validateFormEdit () {
      this.messageResponse = null
      const formStep1 = await this.$refs.formRenderStep1.validateStep()
      const formStep2 = this.$refs.formRenderStep2[0].validateStep()
      const formStep3 = await this.$refs.formRenderStep3[0].validateStep()
      const formStep4 = this.$refs.formRenderStep4[0].validateStep()
      if (!!formStep1 && !!formStep2 && !!formStep3 && !!formStep4 && !!this.form.name_webhook) {
        this.emptyJSON = !this.validateJSON()
        if (!!this.validateJSON()) this.confirmEditWebhook()
      } else {
        this.showToastNotificationError()
      }
    },
    payloadEdit () {
      this.loading.edit = true
      this.loadingSpinner = true
      const statusSelected = this.form.statusAll.filter(status => !!status.saved)
      const statusIDs = statusSelected.map(status => status.id)
      const payload_data = this.form.methods.text !== 'GET' ? this.formatDefaultJson() : null
      const payload = {
        resource_type: this.form.types.id,  
        webhook_id: this.form.id,  
        webhook: {   
          id: this.form.id,       
          resource_webhook_id: this.form.types.id,
          description: this.form.name_webhook,
          payload_data,
          filters: {
            'status.status_id': statusIDs
          },
          exclusions: null,      
          contact_support: null,
          url: this.form.endpoint,
          method: this.form.methods.text,
          headers: this.m_parsedValueObject(this.form.headersArray),
          queue_name: null,
          retriable_http_statuses: null
        },
        user_name: `${this.$session.get('cas_user').first_name} ${this.$session.get('cas_user').last_name}` 
      }
      const params = {}
      const queryParams = payload
      this.$store.dispatch('fetchServiceStatusOnError', { name: 'putWebhook', params, queryParams, onSuccess: (resp) => this.onSuccessPutWebhook(resp), onError: (err) => this.onErrorPutWebhook(err), hideAlert: true})
    },
    onSuccessPutWebhook (resp) {
      this.step5Key++
      this.styleContainerStep5 = false
      this.loading.edit = false
      this.loadingSpinner = false
      this.errorFields = null
      this.errorURL = false
      this.$refs.formRenderStep4[0].selectorConfigurationKey++
      const response = resp.data
      if (response.method !== 'GET') {
        this.messageResponse = {
          message: '¡Validación de campos Ok!',
          code: 200
        }
      }
      const setWebhook = {
        ...response,
        payload: response.payload_data
      }
      this.setWebhook(setWebhook, 'edit')
      this.form.activated = false
      this.webhook.isActive = false
      this.handleBtnsActions(true)
      this.$success(this.$t('msg-exito-actualizar-webhook'))
    },
    onErrorPutWebhook (error) {
      this.loading.edit = false
      this.loadingSpinner = false
      this.messageResponse = null
      this.errorURL = false
      if (error?.data?.selectors) this.errorsInData(error)
      else this.errorFields = null
      if (error?.errors) this.errorsArray(error)
      else if (!error?.data) this.$alert(this.$t('msg-problema-guardar', {code: error.message}), undefined, undefined, undefined, true, 'btn btn-outline-success')
      // Modal en caso que url sea incorrecta
      const url = error?.data && error?.data?.url ? Object.keys(error?.data?.url).length > 0 : false
      if (url) {
        this.errorURL = !!error.data.url.url
        if (!error?.errors) {
          this.$alert('La url tiene algun valor dinámico incorrecto', undefined, undefined, undefined, true, 'btn btn-outline-success')
        }
      }
    },
    errorsInData (error) {
      this.errorFields = error.data.selectors
      this.$refs.formRenderStep4[0].selectorConfigurationKey++
      if (Object.keys(error?.data?.selectors).length > 0) {
        this.messageResponse = {
          message: '¡Error! Verifique los campos.',
          code: 400
        }
      }
    },
    errorsArray (error) {
      const msg = []
      error.errors.forEach((e) => {
        let message = ''
        message = `${e.message} - ${e.code} - ${e.field}` 
        msg.push(message)
      })
      if (error?.data?.url) {
        msg.push('La url tiene algun valor dinámico incorrecto')
      }
      this.$alert(msg.join('.<br>'), null, 'Ocurrió un problema al guardar los datos', '', true, 'btn btn-outline-success')
    },
    showToastNotificationError() {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Oops',
          icon: 'WarningIcon',
          variant: 'danger',
          text: 'No fue posible crear el webhook. No has completado todos los datos obligatorios del formulario.'
        }
      })
    },
    changeStyleContainer (value, tested) {
      this.styleContainerStep5 = value
      this.activeWebhook = tested
    },
    confirmEditWebhook () {
      this.$confirm(this.$t('msg-pregunta-editar-webhook'), () => this.payloadEdit(), 'Advertencia')
    }
  }
}
</script>
<style>
  .container-grid {
  display: grid;
  grid-template-columns: 75% 25%;
  }
  .loading-spinner-edit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(77 77 77 / 70%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 40000
  }
  .icon-hover:hover {
  cursor: pointer;
  }
  .mt-customize {
  margin-top: 0.5rem;
  }
  .mr-customize {
  margin-right: 0.5rem;
  }
  .ml-customize {
  margin-left: 0.5rem;
  }
  .mb-customize {
  margin-bottom: 0.5rem;
  }
  .col-md-customize {
  flex: 0 0 66% !important;
  max-width: 66% !important;
  }
  .switch-webhook 
  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-input:active ~ .custom-control-label::before {
    border-color: #1E8E3E;
    background-color: #1E8E3E;
  }
  .switch-webhook
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before,
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #1f9d57a8 !important;
  }
  .container-cards {
  background-color: #F2F2F8;
  border-radius: 5px;
  }
  .card-customize-edit {
  background-color: #fff;
  }
  .customize-input {
    width: 99.5%;
  }
  .custom-container {
  padding-bottom: 0 !important;
  }
  .custom-btn-edit {
  padding: 0.786rem 2rem;
  }
  .container-center {
    justify-content: center;
    display: flex;
  }
  .aling-step5 {
    height: 90%;
    align-items: center;
  }
  .aling-step5-response-test {
    height: 100%;
    align-items: start;
  }
  .custom-spinner {
    color: #fff;
    width: 10rem; 
    height: 10rem;
  }
  .container-btn-actions {
    background-color: inherit !important;
    padding: 0.5rem 1rem;
  }
  .border-success {
    border: 1px solid #1f9d57 !important;
  }
  .border-danger {
    border: 1px solid #ea5455 !important;
  }
  .border-blue {
    border: 1px solid #044389 !important;
  }
  .form-control:disabled {
    background-color: #f8f8f8 !important;
  }
  @media screen and (max-width: 767px){
  .container-grid {
    display: flex;
    flex-direction: column;
  }  
  .container-grid .steps {
    margin: 0 !important;
  }
  .container-grid .step-5 {
    margin: 1rem 0 !important;
  }
  .col-md-customize {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .container-header-webhook{
    flex-direction: column;
    align-items: center !important;
  }
  }
</style>