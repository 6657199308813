<template>
  <div>
    <section>
      <div class="d-flex align-items-center">
        <h3 class="pr-1">Web de Tracking</h3>
      </div>
      <b-button size="sm" variant="warning" class="mb-2 mt-1" v-b-tooltip.hover title="Personalizar web de tracking"
        @click="openModal('modalWebTracking')">Personalizar web de tracking</b-button>
      <modalWebTracking></modalWebTracking>
    </section>
    <section>
      <h5>Ingrese un envío</h5>
      <b-input-group class="shadow-none">
        <p>
          {{ urlIncomplete }}
        </p>
        <b-form-input class="col-2" v-model="deliveryId"></b-form-input>
        /tracking
        <b-button class="button-check" variant="success"><a :href="urlIncomplete + deliveryId + '/tracking'"
            target="_blank">Revisar</a></b-button>
      </b-input-group>
    </section>
    <section>
      <h5>Campo libre</h5>
      <form-render :fields="fields" containerButtonsClass="col-sm-12" :form.sync="form"
        ref="formFreeField" :key="keyFormFreeField" />
      <b-button @click="updateCustomization" variant="warning" :disabled="loading.save">
        <b-spinner v-show="loading.save" small></b-spinner> Guardar
      </b-button>
    </section>
  </div>
</template>

<script>
import modalWebTracking from './ModalWebTracking.vue'
import { mapGetters } from 'vuex'
import {environment} from '@/environments/environment'

export default {
  data() {
    return {
      deliveryId: '',
      urlIncomplete: '',
      form: {},
      fields: [],
      keyFormFreeField: 0,
      customization: {},
      loading: {
        save: false
      }
    }
  },
  name: 'tabWebTracking',
  components: {
    modalWebTracking
  },
  methods: {
    openModal(nameId) {
      this.$bvModal.show(nameId)
    },
    setInitialData() {
      this.urlIncomplete = `${environment.enviameApiUrl}/s2/companies/${this.$route.params?.id}/deliveries/`
      this.fields = [
        { fieldType: 'FieldTextarea', name: 'tracking_web_message', containerClass: 'col-12 container-info', rows: 12}
      ]
    },
    updateCustomization() {
      this.loading.save = true
      const queryParams = this.customization
      const params = {company_id: this.$route.params?.id}
      queryParams.tracking_web_message = this.form.tracking_web_message

      this.$store.dispatch('fetchService', {
        name: 'updateCustomization',
        params,
        queryParams,
        onSuccess: (d) => {
          this.$bvToast.toast('Campo libre actualizado', {
            title: this.$t('Operación realizada con Exito'),
            variant: 'success',
            solid: true
          })
          this.loading.save = false
        },
        onError: (e) => {
          console.error(e)
          this.loading.save = false
        }
      })

    }
  },
  mounted() {
    this.setInitialData()
  },
  computed: {
    ...mapGetters({
      companyInformation: 'getCompanyInformation'
    })
  },
  watch: {
    companyInformation() {
      this.customization = this.companyInformation?.rows?.company?.customization
      this.form.tracking_web_message = this.customization?.tracking_web_message
      this.keyFormFreeField++
    }
  }
}
</script>

<style>
.button-check {
  margin-left: auto;
}

.input-group {
  align-items: baseline;
}

button a {
  color: white;
}

button a:hover {
  color: white;
}
</style>