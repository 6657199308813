<template>
  <div>
    <b-tabs 
      align="left" 
      v-model="tabIndex"
      :class="[isModal ? 'custom-span' : '', changeColorTabs && !isModal  ? 'change-color-tabs' : 'tabs-custom']"
      >
          <b-tab v-if="showTabBody">
          <template #title>
            <div>
              <span class="pl-1 pr-1"><span>{{$t('Body')}}</span></span>
            </div>
          </template>
          <b-card :class="[isModal ? 'none-box' : '', messageResponse || showErrorJSON || emptyJSON  ? 'mb-0 border-custom' : '']" class="border-top-left">
            <div class="padding-custom">
              <div class="d-flex justify-content-between">
                <form-render
                  ref="formRenderType"
                  :form.sync="formType"
                  :fields="fieldsType"
                  :key="keyFormRenderType">
                </form-render>
                <div v-if="typeBody === this.CONDITIONS.SELECTORS" class="d-flex align-items-center">
                  <b-button class="custom-btn-add" variant="outline-warning" :disabled="!showFields || disabledAllFields || disabledSteps" @click="newSelectorLevelOne()"><feather-icon icon="PlusIcon" size="22"/></b-button>
                  <b-dropdown :disabled="!showFields || disabledAllFields || disabledSteps" variant="link" right toggle-class="text-decoration-none" no-caret class="drodown-custom">
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" size="22" class="color-options"/>
                    </template>
                    <b-dropdown-item v-for="(option, index) in optionsJSON" :key="index" @click="handleItemClick(option, CONDITIONS.SELECTORS)">
                      <span v-if="!option.disabled">{{ option.text }}</span> 
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div v-if="showFields">
                <form-render
                  ref="formRenderStep4"
                  :form.sync="formStep4"
                  :fields="fieldsStep4"
                  :key="keyFormRenderStep4"
                  :disableAll="disabledAllFields || disabledSteps">
                  <template #json-options>
                    <div class="d-flex align-items-center justify-content-end">
                      <div class="mt-1">
                        <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret class="drodown-custom" :disabled="disabledAllFields || disabledSteps">
                          <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="23" class="color-options"/>
                          </template>
                          <b-dropdown-item v-for="(option, index) in optionsJSON" :key="index" @click="handleItemClick(option, CONDITIONS.JSON)">
                          <span v-if="!option.disabled">{{ option.text }}</span> 
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>    
                  </template>
                </form-render>
                <div v-if="showSelectors" :class="isModal ? 'scrollable-container-selectors-modal' : 'scrollable-container-selectors'" class="scrollable col-sm-12 col-md-12 mt-3">
                  <div v-for="(value, key) in formStep4.json" :key="key">
                        <selector-configuration 
                        :form="value"
                        :options="formStep4.selectors"
                        @create="createSubLevel"
                        @config="updateSetting"
                        @remove="removeSetting"
                        :depth="10"
                        :maxDepth="10"
                        :index="key"    
                        :key="selectorConfigurationKey"   
                        ref="selectorConfig"    
                        :errorFields="errorFields"  
                        :disabledAllFields="disabledAllFields || disabledSteps"
                        :isModal="isModal"
                        />
                  </div>
                </div>
              </div>
              <div v-else class="message-empty">
                <span>Seleccione Tipo de estado en el paso 1</span>
              </div>
              <b-button v-if="isModal && !showErrorJSON" @click="validateForm" class="mt-1 push-right" variant="warning" :disabled="!showFields || disabledAllFields || disabledSteps">
                  <span>Guardar cambios</span>
              </b-button>
            </div>
          </b-card>
        </b-tab>
        <b-tab >
          <template #title>
            <div>
            <span class="pl-1 pr-1"><span>{{$t('Headers')}}</span></span>
            </div>
          </template>
          <b-card :class="[isModal ? 'none-box' : '', messageResponse || showErrorJSON || emptyJSON  ? 'mb-0 border-custom' : '']">
            <div class="padding-custom">
              <div class="d-flex justify-content-end mb-2">
                <b-button variant="outline-warning" :disabled="addHeaderButtonDisabled || !showFields || disabledAllFields || disabledSteps" @click="newHeader()" class="custom-btn-add"><feather-icon icon="PlusIcon" size="22"/></b-button>
              </div>
              <div :key="headersKey" v-if="showFields" :class="isModal ? 'scrollable-container-modal' : 'scrollable-container'" class="scrollable">
                <div v-for="(header, index) in formStep4.headersArray" :key="index" class="row mb-1">
                  <div class="col-md-3">
                    <label>Llave</label>
                    <b-form-input :formatter="formatterKey" :disabled="disabledAllFields || disabledSteps" v-model="header.key" placeholder="Ingrese llave"/>
                  </div>
                  <div class="col-md-7">
                    <label>Valor</label>
                    <b-form-input :disabled="disabledAllFields || disabledSteps" v-model="header.value" placeholder="Ingrese valor"/>
                  </div>
                  <div class="col-md-2 d-flex align-items-center">
                    <b-button variant="outline-danger" :disabled="disabledAllFields || disabledSteps" @click="deleteHeader(index)" class="delete-header-btn"><feather-icon icon="TrashIcon" size="16"/></b-button>
                  </div>
                </div>
              </div>
              <div v-else class="message-empty">
                <span>Seleccione Tipo de estado en el paso 1</span>
              </div>
              <b-button v-if="isModal" @click="validateForm" class="mt-1 push-right" variant="warning" :disabled="!showFields || disabledAllFields || disabledSteps">
                  <span>Guardar cambios</span>
              </b-button>
            </div>
          </b-card> 
        </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
import SelectorConfiguration  from './SelectorConfiguration.vue'
export default {
  name: 'step4',
  props: ['form', 'isModal', 'messageResponse', 'errorFields', 'emptyJSON', 'editWebhook'],
  components: {SelectorConfiguration},
  data () {
    return {
      tabIndex: 0,
      formStep4: {
        headersArray: []
      },
      CONDITIONS: Object.freeze({
        JSON: 'json',
        SELECTORS: 'selectors'
      }),
      formType: {},
      fieldsType: [],
      fieldsStep4: [],
      keyFormRenderType: 1,
      keyFormRenderStep4: 0,
      optionsType: [
        { id: 1, text: this.$t('Selectores'), name: 'selectors', disabled: false},
        { id: 2, text: this.$t('JSON'), name: 'json'}
      ],
      optionsJSON: [
        { text: 'Configurar desde cero', value: 'configure' }
      ],
      showFields: false,
      addHeaderButtonDisabled: false,
      addSelectorButtonDisabled: false,
      typeBody: 'selectors',
      showErrorJSON: false,
      parseJSONDefault: null,
      showSelectors: false,
      selectorConfigurationKey: 0,
      showTabBody: true,
      headersKey: 0,
      changeColorTabs: false,
      disabledAllFields: false,
      disabledSteps: false
    }
  },
  watch: {
    'formType': {
      handler (val, oldVal) {
        if (!this.isModal) {    
          this.emitFormStepEvent(val)
        }
      },
      deep: true
    },
    'formStep4': {
      handler (val, oldVal) {
        if (!this.isModal) {
          this.emitFormStepEvent(val)
        }
      },
      deep: true
    },
    'formStep4.headersArray': {
      handler (val, oldVal) {
        const completeHeader = val.filter(item => {
          return item.key && item.value
        })
        this.addHeaderButtonDisabled = completeHeader.length !== val.length
      },
      deep: true
    },
    'form.types': {
      handler (val, oldVal) {
        if (val !== oldVal) this.setStep4(val)
      },
      deep: true
    },
    'form.methods': {
      handler (val, oldVal) {
        if (val !== oldVal) this.handleBodyDisplay(val)
      },
      deep: true
    },
    editWebhook (webhook) {
      const value = webhook?.webhook
      this.changeColorTabs = !!value.id
      
      this.disabledAllFields = value.disabledEdit
      this.formStep4.disabledAllFields = this.disabledAllFields

      this.disabledSteps = value.disabledSteps
      this.formStep4.disabledSteps = this.disabledSteps
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.setField()
      Vue.set(this.formType, 'type', this.optionsType[0])
      this.showSelectors = true
      if (!!this.form.types) {
        this.setInfoModal()
      }
    },
    setInfoStep(val) {
      this.formStep4.selectors = val?.selectors || null

      this.formStep4.json = this.jsonToArray(val?.default_payload) || null
      this.originalJSON = this.jsonToArray(val?.default_payload) || null

      const jsonStringify = JSON.stringify(val?.default_payload, null, 2)      
      this.originalJSONStringify = !!val ? jsonStringify : null
      this.formStep4.jsonStringify = !!val ? jsonStringify : null

      this.handleElementDisplay(val)
    },
    setInfoModal () {
      this.disabledAllFields = this.form.disabledAllFields
      this.disabledSteps = this.form.disabledSteps
      const default_payload = this.form?.types.default_payload
      this.formStep4.jsonStringify = this.form.jsonStringify || null 
      this.originalJSONStringify = !!default_payload ? JSON.stringify(default_payload, null, 2) : null

      this.formStep4.selectors = this.form.selectors
      this.formStep4.type = this.form.type

      this.formStep4.json = this.form.json || []
      this.originalJSON = this.jsonToArray(default_payload) || null

      // Copia para solucionar problemas del headers en el modal
      const copyHeader = this.form.headersArray.length > 0 ? JSON.parse(JSON.stringify(this.form.headersArray)) : []
      this.formStep4.headersArray = copyHeader

      const matchingOption = this.optionsType.find(option => {
        return option.id === this.formStep4.type.id
      })
      this.formType.type = matchingOption
      this.handleSelectorOrJson(this.formStep4.type?.name, true)
      this.handleElementDisplay(this.form.types)
      this.handleBodyDisplay(this.form.methods)
    },
    validateForm () {
      this.emitFormStepEvent(this.formStep4)
      this.emitFormStepEvent(this.formType)
      this.keyFormRenderStep4++    
      this.selectorConfigurationKey++
      this.$emit('renderStep', true)
      this.$emit('hideModal', 4)
    },
    emitFormStepEvent(val) {
      this.$emit('formStep', val)
    },
    setField () {
      this.fieldsType = [   
        {fieldType: 'FieldRadio', name: 'type', type: 'type', align: 'h', label: 'Modo ingreso', options: this.optionsType, change: this.changeType, containerClass: 'col-12', disabled: true}
      ]
    },
    newHeader () {
      const copyHeader = JSON.parse(JSON.stringify(this.formStep4.headersArray))
      copyHeader.push({key: '', value: ''})
      this.headersKey++
      this.formStep4.headersArray = copyHeader 
    },
    deleteHeader (index) {
      const copyHeader = JSON.parse(JSON.stringify(this.formStep4.headersArray))
      if (copyHeader.length  > 1) {
        copyHeader.splice(index, 1)
      }
      this.headersKey++
      this.formStep4.headersArray = copyHeader
    },
    setTypeValue (value, obj) {
      switch (typeof value) {
      case 'string':
        if (value.startsWith('<') && value.endsWith('>')) {
          const filterDynamic = this.formStep4.selectors.filter(option => option.value === value)
          obj.type = filterDynamic.length > 0 ? {id: 1, text:'Dinámico'} : {id: 4, text: 'Valor Fijo'}
        } else {
          obj.type = {id: 4, text: 'Valor Fijo'}
        }
        break
      case 'number':
        obj.type = {id: 2, text: 'Número'}
        break
      case 'boolean':
        obj.type = {id: 5, text: 'Booleano'}
        break
      case 'object':
        if (!!Array.isArray(value)) {
          obj.type = {id: 6, text: 'Matriz'}
        } 
        else if (value === null) {
          obj.type = {id: 7, text: 'Nulo'}
        } else {
          obj.type = {id: 3, text: 'Sub Nivel'}
        }
        break
      default:
        obj.type = {id: 7, text: 'Nulo'}
        break
      }
    },
    jsonToArray(jsonData, parentId = '', typeParent) {
      if (jsonData) {
        const objects = []
        Object.entries(jsonData).forEach(([key, value], index) => {
          const id = parentId ? `${parentId}_${index + 1}` : `${index + 1}`
          const obj = {
            id,
            key
          }
          this.setTypeValue(value, obj)
          if (typeParent && typeParent.id === 6) obj.isChildrenArray = true
          if (typeof value === 'object' && value !== null) {
            obj.value = this.jsonToArray(value, id, obj.type)
          } else {
            obj.value = value
          }
          objects.push(obj)
        })
        return objects
      }
    },
    arrayToJson(arrayData, typeParent = null) {
      const jsonData = typeParent && typeParent.id === 6  ? [] : {}
      arrayData.forEach(obj => {
        const { id, key, value, type, isChildrenArray } = obj
        if (Array.isArray(value)) {
          const childJson = this.arrayToJson(value, type)
          if (typeParent && typeParent.id === 6) {
            jsonData.push(childJson)
          } else {
            jsonData[key] = childJson
          }
        } 
        else if (!!isChildrenArray) {
          jsonData.push(value)  
        } 
        else {
          jsonData[key] = value
        }
      })

      return jsonData
    },
    handleElementDisplay (val) {
      this.optionsType[0].disabled = false
      this.showErrorJSON = false
      
      const indexType = this.getPositionFindIndex(this.fieldsType, 'name', 'type')
      this.fieldsType[indexType].disabled = !val
      this.showFields = val || false
    },
    setStep4 (val) {
      this.formStep4.json = []
      this.formStep4.jsonStringify = null
      const arrayHeaders = val ? JSON.parse(JSON.stringify(val?.default_headers)) : []
      this.formStep4.headersArray = arrayHeaders
      this.optionsJSON = [
        { text: 'Configurar desde cero', value: 'configure' }
      ]
      this.setInfoStep(val)

      Vue.set(this.formType, 'type', this.optionsType[0])
      this.handleSelectorOrJson(this.formType.type.name)
      
      this.keyFormRenderStep4++
      this.selectorConfigurationKey++
      this.headersKey++
    },
    getPositionFindIndex (origin, type,  name) {
      return origin.findIndex(el => el[type] === name)
    },
    changeType (name, value) {
      this.handleSelectorOrJson(value.name)
      if (value.name === this.CONDITIONS.JSON) {
        if (this.formStep4.json.every(objeto => Object.values(objeto).every(valor => valor === null) || objeto.key === '')) this.formStep4.jsonStringify = null
        else this.formStep4.jsonStringify = JSON.stringify(this.arrayToJson(this.formStep4.json), null, 2)
      }
      else {
        const jsonParse = JSON.parse(this.formStep4.jsonStringify)
        this.formStep4.json = this.jsonToArray(jsonParse)
        if (!this.formStep4.json) {
          this.formStep4.json = []
          this.newSelectorLevelOne()
        }
      }
      if (!this.isModal) {
        // Asignamos el tipo de Modo Ingreso al form
        this.formStep4.type = this.formType.type
        this.emitFormStepEvent(this.formStep4)
      }
    },
    handleSelectorOrJson (type, setType) {
      this.typeBody = type
      if (type === this.CONDITIONS.JSON) {
        this.showSelectors = false
        this.fieldsStep4 = [
          { name: 'json-options', useSlot: true, containerClass: 'col-sm-12 col-md-12' },
          {fieldType: 'FieldTextarea', name: 'jsonStringify', containerClass: 'col-sm-12 container-info', placeholder: 'Ingrese el código', rows: 15, change: this.changeJSONTextarea}
        ]
        if (!!setType) this.changeJSONTextarea('jsonStringify', this.formStep4.jsonStringify)
      } else {
        this.showSelectors = true
        this.fieldsStep4 = []
        if (!!setType) this.setOptionsJSON(this.originalJSON, this.formStep4.json)
      }
    },
    handleItemClick(option, type) {
      if (type === this.CONDITIONS.JSON) {
        this.optionsType[0].disabled = false
        this.showErrorJSON = false
        this.$emit('invalidJSON', this.showErrorJSON)
        if (option.value === 'configure') {
          this.formStep4.jsonStringify = null
          this.optionsJSON = [
            { text: 'Restablecer por defecto', value: 'default' }
          ]
        }
        if (option.value === 'default') {
          this.formStep4.jsonStringify = this.originalJSONStringify
          this.optionsJSON = [
            { text: 'Configurar desde cero', value: 'configure' }
          ]
        }
      } else {
        if (option.value === 'configure') {
          this.formStep4.json = []
          this.newSelectorLevelOne()
        } 
        if (option.value === 'default') {
          this.formStep4.json = this.originalJSON
          this.optionsJSON = [
            { text: 'Configurar desde cero', value: 'configure' }
          ]
          this.keyFormRenderStep4++
        }
        // Lo vuelo a montar por problemas en el watch
        this.selectorConfigurationKey++
      }
      if (!this.isModal) {
        // Asignamos el tipo de Modo Ingreso al form
        this.formStep4.type = this.formType.type
        this.emitFormStepEvent(this.formStep4)
      }
    },
    setOptionsJSON (original, form) {
      const originalJson = Array.isArray(original) ? JSON.stringify(original) : original
      const formJson = Array.isArray(form) ? JSON.stringify(form) : form
      if (originalJson === formJson) {
        this.optionsJSON = [{ text: 'Configurar desde cero', value: 'configure' }]
      } 
      else if (form === '' || form === null || (Array.isArray(form) && form.length === 1 && form[0].key === '' && form[0].value === null)) {
        this.optionsJSON = [{ text: 'Restablecer por defecto', value: 'default' }]
      } 
      else {
        this.optionsJSON = [
          { text: 'Restablecer por defecto', value: 'default' },
          { text: 'Configurar desde cero', value: 'configure' }
        ]
      }
    },
    changeJSONTextarea (name, value) {
      if (!!value) {
        try {
          JSON.parse(value)
          this.optionsType[0].disabled = false
          this.showErrorJSON = false
        } catch (error) {
          this.optionsType[0].disabled = true
          this.showErrorJSON = true
        }
      } else {
        this.optionsType[0].disabled = false
        this.showErrorJSON = false
      }
      this.setOptionsJSON(this.originalJSONStringify, value)
      if (!this.isModal) {
        // Asignamos el tipo de Modo Ingreso al form
        this.formStep4.type = this.formType.type
        this.emitFormStepEvent(this.formStep4)
      }
      this.$emit('invalidJSON', this.showErrorJSON)
    },
    updateSetting(form, origin, original, time, type) {
      const updateSelector = JSON.parse(JSON.stringify(this.formStep4.json))
      this.recursiveUpdate(updateSelector, form, original.id, origin, type)
      this.keyFormRenderStep4++
      this.formStep4.json = updateSelector
      this.setOptionsJSON(this.originalJSON, this.formStep4.json)
      if (!this.isModal) {
        // Asignamos el tipo de Modo Ingreso al form
        this.formStep4.type = this.formType.type
        this.emitFormStepEvent(this.formStep4)
      } 
    },
    recursiveUpdate(obj, form, id, origin, type) {
      if (Array.isArray(obj)) {
        obj.forEach((item) => {
          this.recursiveUpdate(item, form, id, origin, type)
        })
      } else if (typeof obj === 'object' && obj !== null) {
        if ('id' in obj && obj['id'] === id) {
          if (origin && origin !== null) {
            obj[origin] = form
          } else {
            obj['type'] = form.type
            obj['value'] = form.value
          }
        }
        Object.keys(obj).forEach((key) => {
          this.recursiveUpdate(obj[key], form, id, origin, type)
        })
      }
    },
    removeSetting(value) {
      // Poder eliminar el hijo cuando solo haya un selector
      const filterSubLevel = this.formStep4.json.filter(item => item.id === value.id)
      if (this.formStep4.json.length > 1 || !filterSubLevel.length > 0) {
        const removeSelector = JSON.parse(JSON.stringify(this.formStep4.json))
        const removeForm = this.recursiveDelete(removeSelector, value)
        this.keyFormRenderStep4++
        this.formStep4.json = removeForm
        this.setOptionsJSON(this.originalJSON, this.formStep4.json)
        // Lo vuelo a montar por problemas en el watch
        this.selectorConfigurationKey++
        if (!this.isModal) {
          // Asignamos el tipo de Modo Ingreso al form
          this.formStep4.type = this.formType.type
          this.emitFormStepEvent(this.formStep4)
        }
      }
    },
    recursiveDelete(obj, value) {
      if (Array.isArray(obj)) {
        return obj.filter(item => {
          if (typeof item.value === 'object' && item.value !== null) {
            item.value = this.recursiveDelete(item.value, value)
          }
          return item.id !== value.id
        })
      } else if (typeof obj === 'object') {
        const newObj = {}
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
              newObj[key] = this.recursiveDelete(obj[key], value)
            } else {
              newObj[key] = obj[key]
            }
          }
        }
        return newObj
      } else {
        return obj
      }
    },
    newSelectorLevelOne () {
      const newSelector = JSON.parse(JSON.stringify(this.formStep4.json))
      // Genero ids unicos
      const newId = uuidv4()
      const newField = {
        id: newId,
        key: '',
        value: null,
        type: null
      }
      newSelector.push(newField)
      this.keyFormRenderStep4++
      this.formStep4.json = newSelector
      this.setOptionsJSON(this.originalJSON, this.formStep4.json)
      if (!this.isModal) {
        // Asignamos el tipo de Modo Ingreso al form
        this.formStep4.type = this.formType.type
        this.emitFormStepEvent(this.formStep4)
      }
      this.$nextTick(() => {
        const scroll = this.isModal ? document.querySelector('.scrollable-container-selectors-modal') : document.querySelector('.scrollable-container-selectors')
        if (scroll) {
          scroll.scrollTop = scroll.scrollHeight
        }
      })
    },
    createSubLevel (value) {
      const newSubJSON = JSON.parse(JSON.stringify(this.formStep4.json))
      this.recursiveCreate(newSubJSON, value)
      this.keyFormRenderStep4++
      this.formStep4.json = newSubJSON
      // Moverse hasta el nuevo campo creado
      this.$nextTick(() => {
        const childSelector = this.isModal ? document.querySelector(`.child-selector-${value.id}-is-modal-true:last-child`) : document.querySelector(`.child-selector-${value.id}-is-modal-false:last-child`)
        childSelector.scrollIntoView({ behavior: 'smooth', block: 'center' })
      })
    },
    recursiveCreate(obj, value) {
      if (Array.isArray(obj)) {
        obj.forEach((item) => {
          this.recursiveCreate(item, value)
        })
      } else if (typeof obj === 'object' && obj !== null) {
        if (obj.id === value.id) {
          // Genero ids unicos para los hijos
          const newId = uuidv4()
          obj.value.push({ id: newId, key: '', value: null, type: null, isChildrenArray: obj.type && obj.type.id === 6})
        } else {
          Object.keys(obj).forEach((key) => {
            this.recursiveCreate(obj[key], value)
          })
        }
      }
    },
    handleBodyDisplay (value) {
      this.showTabBody = value?.text !== 'GET' 
      if (this.showTabBody) {
        this.$nextTick(() => {
          this.tabIndex = 0
        })
      }
    },
    validateStep () {
      return !this.showErrorJSON
    },
    formatterKey(event) {
      return event.replace(/\s/g, '')
    }
  }
}
</script>
<style>
.tabs-custom .nav-tabs .nav-link:after, .change-color-tabs .nav-tabs .nav-link:after {
  content: none;
}
.tabs-custom .nav-tabs .nav-link.active {
  background-color: #fff !important;
}
.change-color-tabs .nav-tabs .nav-link.active, .change-color-tabs .card {
  background-color: #F9FAFE !important;
}
.tabs-custom .nav-tabs .nav-link.active, .change-color-tabs .nav-tabs .nav-link.active {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: #044389;
  font-weight: bold;
}
.tabs-custom .nav-tabs .nav-link, .change-color-tabs .nav-tabs .nav-link {
  color: #6D6B7A;
}
.tabs-custom .nav-tabs, .change-color-tabs .nav-tabs {
  margin-bottom: 0;
}

.delete-header-btn{
  margin-top: 1.5rem;
}
.delete-header-btn {
  padding: 8px !important;
}
.message-empty {
  display: flex;
  justify-content: center;
  padding: 5rem;
}
.hide {
  display: none;
}
.padding-custom {
  padding: 1rem 2rem;
}
.color-options {
  color: #F7901E;
}
.drodown-custom {
  padding: 0.1rem 0;
}
.drodown-custom button {
  padding: 0 0.5rem;
}
.custom-btn-add {
  padding: 0.5rem;
}
.mt-1-5 {
  margin-top: 1.5rem;
}
.border-left{
  border-left: 1px solid #B9B9C7;
}
.scrollable-container-selectors-modal, .scrollable-container-modal {
  overflow-y: auto;
  max-height: 500px;
  height: 500px;
  overflow-x: hidden;
  margin-right: 10px;
}
.scrollable-container, .scrollable-container-selectors {
  overflow-y: auto;
  max-height: 300px;
  height: 300px;
  overflow-x: hidden;
  margin-right: 10px;
}
.scrollable::-webkit-scrollbar {
  width: 6px;
}
.scrollable::-webkit-scrollbar-thumb{
  background-color: #686B6C;
  border-radius: 5px;
}
.padding-custom {
  padding: 20px;
}
.message-empty {
  margin-top: 10px;
  color: #999;
}
.none-box {
  box-shadow: none !important;
}
.border-top-left {
  border-top-left-radius: 0;
}
.border-custom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.custom-span .nav-tabs .nav-link.active span span {
  border-bottom: 2px solid #044389;
}
</style>