<template>
  <div class="container-view">
    <section>
      <p>Al activar las notificaciones, tus clientes recibirán un email cada vez que su envío cambie de estado.
        <b>IMPORTANTE:</b> el logo que subas aquí es el que se verá en el email, no el de la pestaña Info de la empresa.
      </p>
      <b-form-group v-slot="{ ariaDescribedby }" :disabled="disabled.save">
        <b-form-checkbox-group id="checkboxNotify" v-model="notificationsActive" :options="listNotifications"
          :aria-describedby="ariaDescribedby" name="flavour-1" stacked>
        </b-form-checkbox-group>
      </b-form-group>
      <b-button variant="warning" class="bttn" @click="saveCompanyNotifications" :disabled="disabled.save">
        <b-spinner v-show="disabled.save" small></b-spinner> 
        {{ $t('Guardar')}}
      </b-button>
      <b-button variant="outline-secondary" class="bttn" @click="openModal('modalTestNotify')">{{ $t('Enviar prueba')
      }}</b-button>
    </section>
    <section class="img-loader">
      <b-img v-if="!!currentLogo" thumbnail fluid alt="Responsive image" rounded :src="currentLogo" />
      <b-skeleton-img v-else height="150px" width="150px" ></b-skeleton-img>
      <b-button type="file" class="btn btn-warning" :disabled="disabled.logo" @click="$refs.uploadNewLogo.$el.click()" variant="warning">
        <b-spinner v-show="disabled.logo" small></b-spinner>  {{$t('Cargar imagen') }}
      </b-button>
      <b-form-file v-model="newLogo" accept=".jpg, .png, .gif, jpeg" hidden="true" plain ref="uploadNewLogo" />
    </section>
    <ModalTestNotify @onClose="closeModal('modalTestNotify')" ref="modalTestNotify"></ModalTestNotify>
  </div>
</template>
<script>
import ModalTestNotify from './ModalTestNotify.vue'
import BaseServices from '@/store/services'
import defaultLogo from '@/assets/images/icons/company_default_grey.png'

export default {
  name: 'tabAlert',
  props: ['companyInformation'],
  components: {
    ModalTestNotify
  },
  data() {
    return {
      listNotifications: [],
      form: {},
      notificationsActive: [],
      currentLogo: '',
      newLogo: null,
      baseService: new BaseServices(this),
      disabled: {
        save: false,
        logo: false
      }
    }
  },
  methods: {
    setInitialData() {
      this.listNotifications = [
        { text: 'Listo para despacho', value: 'five', disabled: true},
        { text: 'En planta origen', value: 'six', disabled: true },
        { text: 'En reparto', value: 'nine', disabled: true },
        { text: 'Entregado', value: 'ten', disabled: true },
        { text: 'Disponible para retiro', value: 'eleven', disabled: true },
        { text: 'Envío no pudo ser entregado - no hay quien reciba', value: 'nineteen', disabled: true },
        { text: 'Listo para despacho impreso', value: 'twentysix', disabled: true }
      ]
    },
    saveCompanyNotifications() {
      this.disabled.save = true
      const params = { company_id: this.$route.params?.id }
      const queryParams = {
        statuses: this.notificationsActive.length ? Object.assign(...this.notificationsActive.map(not => ({ [not]: true }))) : {nineteen: false}
      }
      this.updateCompanyNotifications(params, queryParams)
    },
    updateCompanyNotifications(params, queryParams) {
      this.$store.dispatch('fetchService', {
        name: 'updateCompaniesNotifications',
        params,
        queryParams,
        onSuccess: (d) => {
          this.$bvToast.toast('Notificaciones actualizadas', {
            title: this.$t('Operación realizada con Exito'),
            variant: 'success',
            solid: true
          })
          this.disabled.save = false
        },
        onError: (e) => {
          this.disabled.save = false
          console.error(e)
        }
      })
    },
    updateCompanyLogoNotifications(params, queryParams) {
      this.baseService.callUploadFileCustomParams('updateCompaniesLogo', queryParams, params)
        .then(resp => {
          this.$bvToast.toast('Nuevo logo actualizado', {
            title: this.$t('Operación realizada con Exito'),
            variant: 'success',
            solid: true
          })
          this.currentLogo = resp.logo
          this.disabled.logo = false
        })
        .catch((err) => {
          this.$alert(this.$t('msg-problema-cargar-datos', { code: err.message }))
          this.disabled.logo = false
        })
    },
    openModal(nameId) {
      this.$bvModal.show(nameId)
    },
    closeModal(nameId) {
      this.$bvModal.hide(nameId)
    }
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    companyInformation() {
      this.notificationsActive = Object.keys(this.companyInformation?.company?.notifications)
      this.listNotifications = this.listNotifications.map(not => ({...not, disabled: false}))
      this.currentLogo = this.companyInformation?.company?.cairoConfig?.logo || defaultLogo
    },
    newLogo() {
      this.disabled.logo = true
      const queryParams = { logo: this.newLogo, type: 'notifications' }
      const query = { company_id: this.$route.params?.id }
      this.updateCompanyLogoNotifications(query, queryParams)
    }
  }
}
</script>
<style scoped>
.container-view {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.img-loader {
  display: grid;
  align-content: space-evenly;
  justify-content: space-evenly;
  justify-items: center;
  grid-gap: 1rem;
}

.img-loader img {
  width: auto;
  height: 80%;
}

.bttn {
  margin: 1rem;
}

.custom-file-input {
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 600px) {
  .container-view {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>