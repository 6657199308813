<template>
  <div> 
     <div v-if="showComponent.create">
      <create-webhook :webhook.sync="currentWebhook" @handleComponentDisplay="handleComponentDisplay"/>
     </div>
     <div v-if="showComponent.edit">
      <edit-webhook :webhook.sync="currentWebhook" @handleComponentDisplay="handleComponentDisplay"/>
     </div>
     <b-card v-if="!showComponent.create && !showComponent.edit">
      <div v-if="showFieldTypeTable" class="d-flex justify-content-between align-items-center mb-1">
        <form-render 
          :key="keyFormRender" 
          class="px-0" 
          :fields="fieldType" 
          :form.sync="formType"
        />
        <div class="row justify-content-end flex-mobile-bottom">
            <b-button variant="warning" class="push-right mr-1" :disabled="loading.first" @click="openNewWebhook()"><feather-icon icon="PlusIcon"/> {{$t('Nuevo Webhook')}}</b-button>             
        </div>
      </div>
      <filter-swapper :trigger="selectedRows.length === 0" id="webhooks_swapper" :buttons="buttons" :controlHeightButtons="controlHeight"  v-if="!loading.changeTypeWebhook">
        <template #slot1>
          <form-render ref="formRender" :key="keyFormRender" class="px-0" :fields="fields" :buttonSend="buttonSend" :form.sync="form" @send="filterList"
            :containerButtonsClass="showFilters">
            <template #extra>
              <div class="col mt-2 mb-1 d-flex justify-content-end align-items-center" v-if="!showFieldTypeTable">
                <div class="row justify-content-end flex-mobile-bottom">
                    <b-button variant="warning" class="push-right mr-1" :disabled="loading.first" @click="openNewWebhook()"><feather-icon icon="PlusIcon"/> {{$t('Nuevo Webhook')}}</b-button>             
                </div>
              </div>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <div v-if="!loading.first">
        <table-render 
        :key="keyTableRender"
        :schema="schema" 
        :rows.sync="rows"
        :stickyHeader="`calc(100vh - 2.5rem)`"
        :actions="actions"
        id="webhooks"
        :tableClass="'with-scroll'"
        :loading="loading.webhooks"
        :emptyHtml="emptyTableMsg"
        >
        <template #isActive="scope">
            <span class="table_dot--state" :class="getStatusClass(scope.rowdata.status)"></span> {{ scope.rowdata.status ? 'Activo' : 'Inactivo' }}
        </template>
        <template #owner="scope">
            <span> {{ scope.rowdata.owner.type }} - {{ scope.rowdata.owner.name }} </span>
        </template>
        </table-render>
      <pagination :pagination="pagination" :noDigits="false" :showSize="true" :disabled="loading.webhooks"/>
      </div>
      <b-skeleton height="40px" width="100%" class="mt-2 mb-2" v-show="loading.first"/>
      <div class="table-render-skeleton" v-show="loading.first">
      <b-skeleton-table   
          :rows="pagination.limit || 10"    
          :columns="schema.length || 10"
          :table-props="{ }"/>
      </div>
    </b-card>
  </div>
</template>
<script>
import CreateWebhook from './CreateWebhook.vue'
import EditWebhook from './EditWebhook.vue'
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'
export default {
  name: 'webhooks',
  props: ['companyInformation'],
  components: {CreateWebhook, EditWebhook},
  data() {
    return {
      selectedRows: [],
      controlHeight: { class: 'row mb-2 spacing-label-field'},
      buttons: [],
      keyFormRender: 0,
      fields: [],
      buttonSend: {icon: 'SearchIcon', color: 'warning', title: 'Filtrar', disabled: true},
      form: {},
      loading: {
        first: true,
        webhooks: false,
        changeTypeWebhook: true
      },
      fieldType: [],
      formType: {},
      keyTableRender: 0,
      schema: [],
      rows: [],
      actions: [
        {action: id => this.goToDetailsWebhook(id), icon: 'FileTextIcon', color: 'primary', text: 'Ver detalle' }
      ],
      pagination: {
        page: 1,
        total: 40,
        limit: 10
      },
      optionsType: [
        { id: 'mkp', text: this.$t('MKP'), name: 'mkp'},
        { id: 'seller', text: this.$t('Seller'), name: 'seller'}
      ],
      showFilters: 'hide',
      CONDITIONS: Object.freeze({
        SELLER: 'seller',
        HIDE: 'hide'
      }),
      typeWebhooks: null,
      queryParamsDefault: true,
      showFieldTypeTable: false,
      baseService: new BaseServices(this),
      emptyTableMsg: 'No se encontraron registros para mostrar',
      showComponentWebhook: false,
      currentWebhook: {},
      showComponent: {
        create: false,
        edit: false
      }
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      webhooks: 'getWebhooks',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    generalLoading: {
      handler() {
        this.loading.webhooks = !!this.generalLoading.getWebhooks
      },
      deep: true
    },
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    webhooks() {
      this.setRows()
    },
    'pagination.page' () {
      this.getWebhooks()
    },
    'pagination.limit' () {
      if (this.pagination.page === 1) this.getWebhooks()
    },
    companyInformation() {
      this.setCompanyInformation()
    }
  },
  mounted () {
    this.getInitialData()
  },
  methods: {
    getInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.setFields()
      this.setSchema()
      if (!!this.companyInformation) {
        this.setCompanyInformation()
      } 
    },
    setCompanyInformation () {
      this.companyData = this.companyInformation.company
      this.showFieldTypeTable = this.companyData.marketplace
      if (this.showFieldTypeTable) {
        this.setDefaultFormType()
      } 
      else {
        this.handleNonMarketplaceCompany()
      }
      this.getWebhooks(true)
    },
    setRows () {
      this.rows = this.webhooks.rows.map(el => {
        return {
          ...el,
          created_at_format: this.$options.filters.dbDateUTCToGMTlocal(el.created_at)
        }
      })
      this.pagination.total = this.webhooks.pagination.meta.data_count
      this.pagination.page = this.webhooks.pagination.meta.page
      this['pagination.limit'] = this.webhooks.pagination.meta.page_count
    },
    setFields () {
      this.fields = [
        {fieldType: 'FieldInput', name: 'name_webhook', label: 'Nombre', containerClass: this.CONDITIONS.HIDE, placeholder:'Ingrese Nombre', clearable:true}
      ]
      this.fieldType = [
        {fieldType: 'FieldRadio', name: 'type', type: 'type', align: 'h', options: this.optionsType, change: this.changeType, containerClass: 'col-12', disabled: true}
      ]
    },
    setSchema () {
      this.schema = [
        {label: 'Nombre', sortable: true, key: 'name'},
        {label: 'Tipo', sortable: true, key: 'type.name' },
        {label: 'Estado', useSlot: true, key: 'isActive'},
        {label: 'Fecha creación', sortable: true, key: 'created_at_format'},
        {label: 'Acciones', key: 'actions', class: ['text-center']}
      ]
    },
    setDefaultFormType() {
      this.formType.type = this.optionsType[0]
      this.typeWebhooks = this.optionsType[0]
    },
    handleNonMarketplaceCompany () {
      this.isDifferentOrgId = Number(this.companyData.dubai_organization_id) !== Number(this.companyData.dubai_shipper_id)
      if (this.isDifferentOrgId) {
        this.insertOwnerField()
      }
    },
    getWebhooks (handle_name_display = false) {
      const { id, marketplace } = this.companyData
      const params = {}
      const queryParams = {
        ...this.payloadFilter,
        'paginate_by': this.pagination.limit,
        'page': this.pagination.page
      }
      if (this.queryParamsDefault) {
        marketplace ? queryParams.organization_id = id : queryParams.shipper_id = id
        if (this.typeWebhooks?.name === this.CONDITIONS.SELLER) queryParams.list_sellers = true
      }
      this.$store.dispatch('fetchService', { name: 'getWebhooks', params, queryParams, onSuccess: (data) => { 
        const data_count = data.response?.links.meta.data_count
        this.loadingWebhooks(false)
        if (handle_name_display)  this.showFieldNameWebhook(data_count)
        this.showFilters = data_count >= 10 || this.fields.some(obj => obj.name !== 'name_webhook') ? 'col-md-1 mt-2 mb-1 d-flex align-items-center' : this.CONDITIONS.HIDE
      }})
    },
    showFieldNameWebhook (resp) {
      const indexName = this.getPositionFindIndex(this.fields, 'name', 'name_webhook')
      this.fields[indexName].containerClass = resp >= 10 ? 'col-md-3 col-sm-12 container-info name_webhook' : this.CONDITIONS.HIDE
    },
    loadingWebhooks (disabled, loading = false) {
      this.fieldType[0].disabled = disabled
      this.buttonSend.disabled = disabled
      this.loading.first = loading
      this.loading.changeTypeWebhook = loading
    },
    filterList (form) {
      this.queryParamsDefault = true
      this.loadingWebhooks(true)
      this.payloadFilter = {}
      Object.keys(form).map(key => {
        this.payloadFilter[key] = form[key].id || form[key]
      })
      this.emptyTableMsg = '<p>No se encontraron registros para mostrar.</p>'
      this.queryParamnsForSellerFilter(form)
      if (this.pagination.page !== 1) this.pagination.page = 1
      else this.getWebhooks()
    },
    queryParamnsForSellerFilter (form) {
      if (this.payloadFilter.shipper_id && this.companyData.marketplace) {
        this.payloadFilter.list_mkp = false
        this.queryParamsDefault = false
        if (!form.name_webhook) this.emptyTableMsg = `<p>${form.shipper_id.text} No tiene webhooks propios creados.</p>`
      }
    },
    openNewWebhook () {
      const { marketplace, dubai_organization_id, dubai_shipper_id, name1 } = this.companyData
      this.currentWebhook = {organization_id: dubai_organization_id, shipper_id: dubai_shipper_id, marketplace, name1}
      this.handleComponentDisplay('create')
    },
    getStatusClass(status) {
      return status ? 'table_dot--state-ok' : 'table_dot--status-inactivated'
    },
    goToDetailsWebhook (id) {
      const row = this.rows.filter(row => row.id === id)[0]
      const { marketplace, dubai_organization_id, dubai_shipper_id, name1 } = this.companyData
      const disabledEdit = !marketplace && name1 !== row.owner.name
      const ownerType = name1 === row.owner.name ? 'Propio' : row.owner.type
      this.currentWebhook = {id, organization_id: dubai_organization_id, shipper_id: dubai_shipper_id, marketplace, owner: {text: `${ row.owner.name } - ${ ownerType }`}, disabledEdit, isActive: row.status}
      if (this.formType?.type && this.formType?.type.name === this.CONDITIONS.SELLER) this.currentWebhook.owner = {...this.currentWebhook.owner, id: row.owner.id}
      this.handleComponentDisplay('edit')
    },
    changeType (name, value) {
      this.loading.changeTypeWebhook = true
      this.queryParamsDefault = true
      this.resetData()
      this.typeWebhooks = value
      this.fieldType[0].disabled = true
      this.manageFieldsAndSchemaByType()
      this.getWebhooks(true)
    },
    resetData () {
      this.showFilters = this.CONDITIONS.HIDE
      this.form = {}
      this.payloadFilter = {}
      this.loadingWebhooks(true, true)
    },
    removeElementByIndex(arr, index) {
      arr.splice(index, 1)
    },
    insertElementAtIndex(arr, index, element) {
      arr.splice(index, 0, element)
    },
    insertOwnerField () {
      const indexStatus = this.getPositionFindIndex(this.schema, 'label', 'Estado')
      const newSchema = {label: 'Propietario', useSlot: true, key: 'owner' }
      const indexName = this.getPositionFindIndex(this.fields, 'name', 'name_webhook')
      const newField = {fieldType: 'FieldSelect', name: 'company_type', label: 'Propietario', containerClass: 'col-md-3 col-sm-12 container-info', options: this.optionsType}
      this.insertElementAtIndex(this.schema, indexStatus + 1, newSchema)
      this.insertElementAtIndex(this.fields, indexName, newField)
    },
    manageFieldsAndSchemaByType() {
      const indexName = this.getPositionFindIndex(this.fields, 'name', 'name_webhook')
      const indexShipper = this.getPositionFindIndex(this.fields, 'name', 'shipper_id')
      const indexStatus = this.getPositionFindIndex(this.schema, 'label', 'Estado')
      const indexOwner = this.getPositionFindIndex(this.schema, 'label', 'Propietario')
      this.fields[indexName].containerClass = this.CONDITIONS.HIDE
      if (this.typeWebhooks?.name === this.CONDITIONS.SELLER) {   
        if (indexShipper === -1) {
          const newField = {fieldType: 'FieldAutocomplete', name: 'shipper_id', label: 'Seller', containerClass: 'col-md-3 col-sm-12 container-info', searchOnType: { fx: this.searchNameSeller, nChar: 3, debounce: 300, allowSearchAsValue: true, persistSearch: true }, placeholder:'Ingrese nombre o código', clearable: true}
          this.insertElementAtIndex(this.fields, indexName, newField)
        }
        if (indexOwner === -1) {
          const newSchema = {label: 'Propietario', useSlot: true, key: 'owner' }
          this.insertElementAtIndex(this.schema, indexStatus + 1, newSchema)
        }
      } else {
        if (indexShipper !== -1) {
          this.removeElementByIndex(this.fields, indexShipper)
        }
        if (indexOwner !== -1) {
          this.removeElementByIndex(this.schema, indexOwner)
        }
      }
    },
    getPositionFindIndex (origin, type,  name) {
      return origin.findIndex(el => el[type] === name)
    },
    searchNameSeller (value) {
      const { id } = this.companyData
      const params = {
        organization_id: id
      }
      const queryParams = {
        'name__or__code__like': value
      }
      return this.baseService.callService('getShippersByOrganizationSimplifiedQueryParams', queryParams, params)
        .then(resp => {
          const response = resp.data.map(el => ({...el, text: `${el.name} - ID ${el.id}`}))
          return response
        })
        .catch(err => {
          return err
        })
    },
    handleComponentDisplay (type) {
      this.showComponent.create = type === 'create'
      this.showComponent.edit = type === 'edit'
      if (type === 'list') {
        this.payloadFilter = {}
        this.getWebhooks()
      }
    }
  }
}
</script>
<style>
.hide{
  display: none;
}
.name_webhook .container--icon-right .form-control{
  border-radius: 0.357rem;
}
</style>