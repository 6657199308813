<template>
    <div>
      <div v-if="loadingSpinner" class="loading-spinner-create">
        <b-spinner class="custom-spinner"/>
      </div>
      <b-card>
        <div class="d-flex align-items-end justify-content-between container-header-webhook">
          <div class="d-flex align-items-center mb-1">
           <feather-icon
              class="icon-hover mr-2"
              icon="ChevronLeftIcon"
              size="24"
              @click="returnWebhooks()"
            />
            <div class="d-flex align-items-center">
              <img class="mr-customize" :src="require(`@/assets/images/webhooks/webhook.svg`)" alt="webhook">
              <h1 class="mb-0" ><strong>Nuevo Webhook</strong></h1>
            </div> 
          </div>
        </div>
        <div class="row justify-content-between p-1 custom-container">
          <div v-for="(step, index) in steps" :key="index" class="card card-customize col-md-12">
            <header-step 
              :step="step" 
              :showStep="$t(`Paso ${step.id}`)" 
              :form="form" 
              @formStep="setForm" 
              @renderStep="renderStep" 
              :messageResponse="messageResponse" 
              :errorFields="errorFields"/>
            <component 
              :key="onlyRenderStep4(step)" 
              :ref="`formRenderStep${step.id}`" 
              :isModal="false" 
              class="mt-2" 
              :is="computedComponent[index]" 
              @formStep="setForm" 
              :form="form" 
              :messageResponse="messageResponse" 
              :errorFields="errorFields"
              @invalidJSON="invalidJSON"
              :emptyJSON="emptyJSON"
              :errorURL="errorURL"/>
            <div class="mb-1 container-message" v-if="step.id === 4 && (messageResponse || showErrorJSON || emptyJSON) && hideMessageAlert">
              <div v-if="messageResponse && !showErrorJSON && !emptyJSON">
                <feather-icon
                  :icon="messageResponse.code === 200 ? 'CheckCircleIcon' : 'XCircleIcon'"
                  size="15"
                  :class="messageResponse.code === 200 ? 'text-success' :'text-danger'" 
                  class="mr-customize"
                />
                <span>{{ messageResponse.message }}</span>
              </div>
              <div v-if="showErrorJSON || emptyJSON">
                <feather-icon
                  icon="XCircleIcon"
                  size="15"
                  class="text-danger mr-customize"
                />
                <span>¡JSON inválido! Por favor verifique los campos.</span>
              </div>
            </div>
          </div>
        </div>
        <b-button :disabled="loading.create || !form.types" @click="validateForm" class="push-right custom-btn-create" variant="warning">
          <b-spinner small v-if="loading.create"/>
          <span v-else>Crear</span>
        </b-button>
      </b-card>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import HeaderStep from './components/headerStep/Header.vue'
import Step1 from './components/step/Step1.vue'
import Step2 from './components/step/Step2.vue'
import Step3 from './components/step/Step3.vue'
import Step4 from './components/step/Step4.vue'
import Step5 from './components/step/Step5.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  name: 'create-webhook',
  components: { Step1, Step2, Step3, Step4, Step5, HeaderStep },
  props: ['webhook'],
  data () {
    return {
      loadingSpinner: true,
      form: {},
      steps: [
        {id: 1, title: 'Datos del Webhook.'},
        {id: 2, title: 'Eventos que activan el Webhook.'},
        {id: 3, title: 'Endpoint del Webhook.'},
        {id: 4, title: 'Contenido del Webhook.'}
      ],
      step4Key: 0,
      defaultKey: 0,
      loading: {
        create: false
      },
      messageResponse: null,
      errorFields: null,
      showErrorJSON: false,
      emptyJSON: false,
      hideMessageAlert: false,
      errorURL: false
    }
  },
  computed: {
    computedComponent() {
      return this.steps.map((step) => {
        return `Step${step.id}`
      })
    },
    ...mapGetters({
      createWebhook: 'postWebhook'
    })
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.getTypesWebhooks()
      this.loadingSpinner = false
    },
    getTypesWebhooks () {
      const params = {}
      const queryParams = {}
      this.$store.dispatch('fetchService', { name: 'getTypeWebhook', params, queryParams})  
    },
    returnWebhooks () {
      this.$emit('handleComponentDisplay', 'list')
    },
    onlyRenderStep4 (step) {
      return step.id === 4 ? this.step4Key : this.defaultKey
    },
    renderStep (value) {
      if (value) this.step4Key++
    },
    invalidJSON (value) {
      this.showErrorJSON = value
    },
    setForm (data) {
      this.form = {
        ...this.form,
        ...data
      }
      this.hideMessageAlert = this.form?.methods && this.form.methods.text !== 'GET'
    },
    arrayToJson(arrayData, typeParent = null) {
      const jsonData = typeParent && typeParent.id === 6  ? [] : {}
      arrayData.forEach(obj => {
        const { id, key, value, type, isChildrenArray } = obj
        if (Array.isArray(value)) {
          const childJson = this.arrayToJson(value, type)
          if (typeParent && typeParent.id === 6) {
            jsonData.push(childJson)
          } else {
            jsonData[key] = childJson
          }
        } 
        else if (!!isChildrenArray) {
          jsonData.push(value)  
        } 
        else {
          jsonData[key] = value
        }
      })
      
      return jsonData
    },
    // Si es un JSON valido deja crear, sino sale alerta
    validateJSON () {
      if (this.form?.methods && this.form?.type && (this.form?.json || this.form?.jsonStringify)) {
        if (this.form.methods?.text !== 'GET') {
          if (this.form.type?.id === 1 && !(this.form.json[0].key === '' && this.form.json[0].value === null && this.form.json.length === 1)) return true
          else if (this.form.type?.id === 2 && this.form.jsonStringify) return true
          else return false
        } 
        else return true
      } else return false  
    },
    // Formateamos el JSON segun el tipo que tenga seleccionado el usuario Selectores/JSON
    formatDefaultJson () {
      if (this.form.type.id === 1) {
        return this.arrayToJson(this.form.json)
      } else {
        return JSON.parse(this.form.jsonStringify)
      }
    },
    // Validamos los pasos
    async validateForm () {
      this.messageResponse = null
      const formStep1 = await this.$refs.formRenderStep1[0].validateStep()
      const formStep2 = this.$refs.formRenderStep2[0].validateStep()
      const formStep3 = await this.$refs.formRenderStep3[0].validateStep()
      const formStep4 = this.$refs.formRenderStep4[0].validateStep()
      
      if (!!formStep1 && !!formStep2 && !!formStep3 && !!formStep4) {
        this.emptyJSON = !this.validateJSON()
        if (!!this.validateJSON()) this.payloadCreate()
      } else {
        this.showToastNotificationError()
      }
    },
    // Creamos el payload
    payloadCreate () {
      this.loading.create = true
      this.loadingSpinner = true
      const statusSelected = this.form.statusAll.filter(status => !!status.saved)
      const statusIDs = statusSelected.map(status => status.id)
      const payload_data = this.form.methods.text !== 'GET' ? this.formatDefaultJson() : null
      const payload = {
        resource_type: this.form.types.id,  
        organization_id: this.webhook.organization_id,
        shipper_id: this.webhook.shipper_id,      
        webhook: {          
          resource_webhook_id: this.form.types.id,
          description: this.form.name_webhook,
          payload_data,
          filters: {
            'status.status_id': statusIDs
          },
          exclusions: null,      
          contact_support: null,
          url: this.form.endpoint,
          method: this.form.methods.text,
          headers: this.m_parsedValueObject(this.form.headersArray),
          queue_name: null,
          retriable_http_statuses: null
        },
        user_name: `${this.$session.get('cas_user').first_name} ${this.$session.get('cas_user').last_name}` 
      }
      const params = {}
      const queryParams = payload
      this.$store.dispatch('fetchServiceStatusOnError', { name: 'postWebhook', params, queryParams, onSuccess: (resp) => this.onSuccessPostWebhook(resp), onError: (err) => this.onErrorPostWebhook(err), hideAlert: true})
    },
    onSuccessPostWebhook (resp) {
      this.loading.create = false
      this.loadingSpinner = false
      this.errorFields = null
      this.errorURL = false
      this.$refs.formRenderStep4[0].selectorConfigurationKey++
      this.messageResponse = {
        message: '¡Validación de campos Ok!',
        code: 200
      }
      const response = resp.data
      this.webhook.id = response.webhook.id
      this.webhook.owner = {text: `${this.webhook.name1} - Propio`}
      this.webhook.response_create = response
      this.$emit('handleComponentDisplay', 'edit')
    },
    onErrorPostWebhook (error) {
      this.loading.create = false
      this.loadingSpinner = false
      this.messageResponse = null
      this.errorURL = false
      if (error?.data?.selectors) this.errorsInData(error)
      else this.errorFields = null
      if (error?.errors) this.errorsArray(error)
      else if (!error?.data) this.$alert(this.$t('msg-problema-guardar', {code: error.message}), undefined, undefined, undefined, true, 'btn btn-outline-success')
      // Modal en caso que url sea incorrecta
      const url = error?.data && error?.data?.url ? Object.keys(error?.data?.url).length > 0 : false
      if (url) {
        this.errorURL = !!error.data.url.url
        if (!error?.errors) {
          this.$alert('La url tiene algun valor dinámico incorrecto', undefined, undefined, undefined, true, 'btn btn-outline-success')
        }
      }
    },
    errorsInData (error) {
      this.errorFields = error.data.selectors
      this.$refs.formRenderStep4[0].selectorConfigurationKey++
      if (Object.keys(error?.data?.selectors).length > 0) {
        this.messageResponse = {
          message: '¡Error! Verifique los campos.',
          code: 400
        }
      }
    },
    errorsArray (error) {
      const msg = []
      error.errors.forEach((e) => {
        let message = ''
        message = `${e.message} - ${e.code} - ${e.field}` 
        msg.push(message)
      })
      if (error?.data?.url) {
        msg.push('La url tiene algun valor dinámico incorrecto')
      }
      this.$alert(msg.join('.<br>'), null, 'Ocurrió un problema al guardar los datos', '', true, 'btn btn-outline-success')
    },
    showToastNotificationError() {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Oops',
          icon: 'WarningIcon',
          variant: 'danger',
          text: 'No fue posible crear el webhook. No has completado todos los datos obligatorios del formulario.'
        }
      })
    }
  }
}
</script>
<style>
.loading-spinner-create {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(77 77 77 / 70%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 40000
}
.icon-hover:hover {
  cursor: pointer;
}
.mt-customize {
  margin-top: 0.5rem;
}
.mr-customize {
  margin-right: 0.5rem;
}
.ml-customize {
  margin-left: 0.5rem;
}
.col-md-customize {
  flex: 0 0 66% !important;
  max-width: 66% !important;
}
.card-customize {
  background-color: #F2F2F8;
  
}
.custom-container {
  padding-bottom: 0 !important;
}
.custom-btn-create {
  padding: 0.786rem 2rem;
}
.container-message {
  background: #F9F9F9;
  padding: 0.5rem 3rem;
  border-bottom-left-radius: 0.428rem;
  border-bottom-right-radius: 0.428rem;
}
.custom-spinner {
  color: #fff;
  width: 10rem; 
  height: 10rem;
}
@media screen and (max-width: 767px){
  .col-md-customize {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .container-header-webhook{
    flex-direction: column;
    align-items: center !important;
  }
}
</style>