<template>
  <div class="container-customize">
    <section class="col-md-12">
      <p>Activa las notificaciones de acuerdo a la información que le quieras permitir a tus clientes entregar dependiendo de la incidencia que tenga con su envío.</p>
      <p>*Esto aplica únicamente a clientes con cuentas de Distribución o Connect que hayan habilitado el servicio de BackOffice.</p>
    </section>
    <section class="col-md-12">
      <h3 class="h5 pt-1">Reporte de incidencia post entrega</h3>
      <p>Enviaremos un correo al cliente al momento de la entrega, donde podrá informarnos  y adjuntar pruebas de incidencias que haya tenido con su envío.</p>
      <div class="padding-customize">
        <b-form-checkbox @change="updateEnableMailDelivered('post_delivery')" v-model="enableMailDelivered.post_delivery" switch>
          <span>
            {{ enableMailDelivered.post_delivery ? 'Activo' : 'Inactivo' }} <b-spinner v-show="load.status" small></b-spinner>
          </span>
        </b-form-checkbox>
      </div>
      <p>Recibir prueba del correo</p>
      <p><b>Correo</b></p>
      <b-input-group>
        <b-form-input class="col-10" v-model="emailTest.post_delivery" :state="validEmail.post_delivery" type="email" label="Correo" placeholder="example@example.com"></b-form-input>
        <b-button class="button-check ml-0 px-1 rounded-0 close-btn-border" variant="link" @click="() => emailTest.post_delivery = ''"><feather-icon icon="XIcon"/></b-button>
        <b-button class="button-check ml-2" variant="warning" @click="sendEmailTest('post_delivery')" :disabled="!emailTest.post_delivery || loading"><b-spinner v-show="loading" small></b-spinner> Enviar</b-button>
      </b-input-group>
      <h3 class="border-top-secondary h5 mt-2 pt-4">Verificación de dirección por intento fallido de entrega.</h3>
      <p>Enviaremos un correo al cliente para que pueda confirmar o corregir su dirección de destino al momento de crearse una incidencia ante un intento fallido de entrega.</p>
      <div class="padding-customize">
        <b-form-checkbox @change="updateEnableMailDelivered('right_address')" v-model="enableMailDelivered.right_address" switch>
          <span>
            {{ enableMailDelivered.right_address ? 'Activo' : 'Inactivo' }} <b-spinner v-show="load.status" small></b-spinner>
          </span>
        </b-form-checkbox>
      </div>

      <p>Recibir prueba del correo</p>
      <p><b>Correo</b></p>
      <b-input-group>
        <b-form-input class="col-10" v-model="emailTest.right_address" :state="validEmail.right_address" type="email" label="Correo" placeholder="example@example.com"></b-form-input>
        <b-button class="button-check ml-0 px-1 rounded-0 close-btn-border" variant="link" @click="() => emailTest.right_address = ''"><feather-icon icon="XIcon"/></b-button>
        <b-button class="button-check ml-2" variant="warning" @click="sendEmailTest('right_address')" :disabled="!emailTest.right_address || loading"><b-spinner v-show="loading" small></b-spinner> Enviar</b-button>
      </b-input-group>
    </section>
    <section class="col-12 mt-2 hover-question-sec">
      <h3 class="text-primary">
        Preguntas Frecuentes 
        <a target="_blank" href="https://www.youtube.com/@enviame/videos"><feather-icon icon="PlayCircleIcon" size="21" /></a>
      </h3>

      <app-collapse accordion class="row block version--list">
        <app-collapse-item title="" v-for="(item, index) in questions" :key="index">
          <template #header>
            <div class="container-info">
              <a class="cursor-pointer lnk lnk-primary">{{ item.question }}</a>
            </div>
          </template>
          <b-card>
            <p>{{ item.answer }}</p>
          </b-card>
        </app-collapse-item>
      </app-collapse>

    </section>
  </div>
</template>

<script>

export default {
  props: ['companyInformation'],
  data() {
    return {
      enableMailDelivered: {
        post_delivery: false,
        right_address: false
      },
      shipper: {},
      keyCheckBoxMail: 0,
      emailTest: {
        post_delivery: '',
        right_address: ''
      },
      validEmail: {
        post_delivery: false,
        right_address: false
      },
      loading: false,
      urlImg: 'mail_logo',
      questions:  [
        {question: '¿Desde dónde se obtiene mi logotipo para el correo?', answer: 'Desde módulo Alertas Clientes.'},
        {question: '¿Qué implica la nueva funcionalidad de alerta incidencia cliente?', answer: 'Permite a tus clientes reportar directamente inconvenientes, daños, faltantes y cambios de dirección; ahorrándote tiempo y optimizando la de gestión de tickets.'}
      ],
      load: {
        status: false
      }
    }
  },
  watch: {
    companyInformation() {
      this.setValues()
    }
  }, 
  methods: {
    updateEnableMailDelivered(source) {
      this.load.status = true
      const params = { shipper_code: this.shipper.code }
      const queryParams = { email_notification: this.enableMailDelivered }
      this.$store.dispatch('fetchService', {
        name: 'updateConfiguration',
        params,
        queryParams,
        onSuccess: (d) => {
          this.$bvToast.toast('Mail de entrega actualizado', {
            title: this.$t('Operación realizada con Exito'),
            variant: 'success',
            solid: true
          })
          this.load.status = false
          this.getShipperConfig()
        },
        onError: (e) => {
          console.error(e)
          this.load.status = false
          this.getShipperConfig()
        }
      })
    },
    getShipperConfig() {
      const params = { shipper_code: this.shipper.code }
      this.$store.dispatch('fetchService', {
        name: 'configurationShipper',
        params,
        onSuccess: (d) => {
          this.enableMailDelivered = d.data.email_notification
        },
        onError: (e) => {
          console.error(e)
        }
      }
      )
    },
    sendEmailTest(source) {
      const isWrongEmail = this.m_validateEmail([this.emailTest[source]])
      this.validEmail[source] = !isWrongEmail
      if (isWrongEmail) {
        this.validEmail[source] = false
        return
      }
      let service = ''
      switch (source) {
      case 'right_address':
        service = 'sendEmailTestAddress'
        break
      case 'post_delivery':
        service = 'sendEmailTestDelivery'
        break
      }
      this.loading = true
      this.validEmail[source] = null
      const queryParams = {
        carrier: {
          id: 0,
          code: 'BLX',
          name: 'BLUEXPRESS'
        },
        company: {
          name: this.shipper.name,
          id: this.shipper.id,
          code: this.shipper.code
        },
        customer: {
          email: this.emailTest[source],
          full_name: 'Correo ejemplo'
        },
        status:{
          id: 10,
          code: 'DELIVERED_DOM'
        },
        identifier: 0,
        test_token: true,
        tracking_number: 'tracking',
        shipping_address:{
          full_address: 'Calle Test 123, Urb. San Fierro, Los Santos',
          place: 'Los Santos, USA'
        }
      }


      this.$store.dispatch('fetchService', {
        name: service,
        queryParams,
        onSuccess: (d) => {
          this.$bvToast.toast('Mail de prueba enviado', {
            title: this.$t('Operación realizada con Exito'),
            variant: 'success',
            solid: true
          })
          this.emailTest = {
            post_delivery: '',
            right_address: ''
          }
          this.loading = false
        },
        onError: (e) => {
          console.error(e)
          this.loading = false
        }
      })
    },
    setValues() {
      this.shipper = {
        id: this.companyInformation?.company?.id,
        name: this.companyInformation?.company?.name1,
        code: this.companyInformation?.company?.code
      }
      this.getShipperConfig()
      if (!!this.companyInformation.company.logo_verify) this.urlImg = 'mail_enviame'
    }
  },
  mounted() {
    this.setValues()
  }
}

</script>
<style scoped lang="scss">
  
.container-customize {
  display: flex;
  flex-wrap: wrap;
}
.container-customize {
  p {
    margin: 1rem 0;
  }
  .container-info {
    font-size: 16px;
  } 
  .border-top-secondary {
    border-top: 1px solid #DDE0E4;
  }
  .close-btn-border {
    color: #d8d6de;
    border-color: #d8d6de;
  }
}

.container-email-test {
  padding: 2rem 5rem;
  background: #FBF9F9;
  display: flex;
  justify-items: center;
}

.padding-customize {
  padding: 1rem 0rem 1rem
}


.container-email-test {
  max-width: 80%;
  margin: auto;
}
.hover-question-sec a{
  font-weight: bold;
}
.hover-question-sec a:hover{
  color: #1ab394;
}

@media (max-width: 768px){
  .container-email-test {
    width: 50%;
  }
}
</style>