<template>
  <div>
    <b-card>
      <div class="tab-header-content">
        <b-tabs>
          <b-tab :title="$t('Alertas clientes')">
            <TabAlert :companyInformation="companyData"></TabAlert>
          </b-tab>
          <b-tab :title="$t('Web tracking')">
            <TabWebTracking></TabWebTracking>
          </b-tab>
          <b-tab v-if="showMailDelivery">
            <template #title>
              <p class="m-0 mr-2">Alertas incidencia clientes</p><b-badge class="label-success-new" variant="success">NUEVO</b-badge>
            </template>
            <TabMailDeliveredClient :companyInformation="companyData"></TabMailDeliveredClient>
          </b-tab>
          <b-tab v-if="can_access_return" :title="$t('Formato correo devoluciones')">
            <TabFormatEmailReturn :companyInformation="companyData"></TabFormatEmailReturn>
          </b-tab>
          <b-tab v-if="isAdmin" ref="webhookTab">
            <template #title>
              <span>{{$t('Webhooks')}}</span>
              <b-badge class="ml-05 label-success-new" variant="success">NUEVO</b-badge>
            </template>
            <TabWebhooks :companyInformation="companyData"></TabWebhooks>      
          </b-tab>
        </b-tabs>
      </div>
    </b-card>
  </div>
</template>
<script>
import TabAlert from './tabs/TabAlert.vue'
import TabWebTracking from './tabs/TabWebTracking.vue'
import TabMailDeliveredClient from './tabs/TabMailDeliveredClient.vue'
import TabFormatEmailReturn from './tabs/TabFormatEmailReturn.vue'
import TabWebhooks from '../Webhooks/List.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'TabNotify',
  props: ['changeTabWebhook'],
  components: {
    TabAlert,
    TabWebTracking,
    TabMailDeliveredClient,
    TabFormatEmailReturn,
    TabWebhooks
  },
  data () {
    return {
      listNotifications: [],
      form: {},
      can_access_return: false,
      companyData: '',
      showMailDelivery: false,
      isAdmin: false
    }
  },
  methods: {
    setInitialData () {
      this.isAdmin = ['superadmin', 'admin'].includes(this.mySession?.role)
      this.can_access_return =  this.mySession?.user_access?.can_access_return
      if (!Object.keys(this.companyInformation).length) this.getCompanyInfo()
      else this.setCompanyData()
      if (!!this.changeTabWebhook) this.setTabWebhooks()
      
    },
    getCompanyInfo() {
      this.$store.dispatch('fetchService', { name: 'getCompanyInformation', params: {company_id: this.$route.params?.id} })
    },
    setCompanyData() {
      this.companyData = this.companyInformation.rows
      if (!this.companyData.company.marketplace) this.showMailDelivery = true
    },
    setTabWebhooks () {
      this.$nextTick(() => {
        this.$refs.webhookTab.activate()
      })
    }
  },
  mounted () {
    this.setInitialData()
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      companyInformation: 'getCompanyInformation'
    })
  },
  watch: {
    mySession () {
      this.isAdmin = ['superadmin', 'admin'].includes(this.mySession.role)
      this.can_access_return = this.mySession.user_access.can_access_return
    },
    companyInformation() {
      this.setCompanyData()
    },
    changeTabWebhook (value) {
      if (!!value) this.setTabWebhooks()
    }
  }
}
</script>
<style>
.label-success-new {
  width: 40px;
  height: 20px;
  color: #f5f5f5;
  background-color: #6cbd1a;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 8px;
  border-radius: 4px;
}
</style>