<template>
  <b-modal id="modalWebTracking" :title="$t('Personalizar web de tracking')" no-close-on-backdrop centered
    modal-class="dialog-1200" hide-footer>
    <Personalize></Personalize>
  </b-modal>
</template>
<script>
import Personalize from '@/views/modules/web-tracking/Personalize.vue'
export default {
  components: {
    Personalize
  },
  data() {
    return {
      keyFormTestNotify: 0
    }
  }
}
</script>
<style></style>