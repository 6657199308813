<template>
  <div>
    <b-modal id="modalKeyWords" :title="$t('Detalle de Notificación')" no-close-on-backdrop size="md" hide-footer>
      <table-render :schema="schema" :rows="rows" class="hide-overflow-x">
        <template #add_action="scope">
          <b-button variant="link" @click="addKeyword(scope.rowdata.name_key)"><feather-icon icon="FileTextIcon" size="16" /></b-button>
        </template>
      </table-render>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'modalKeyWords',
  props: ['updateForm'],
  data() {
    return {
      fields: [],
      schema: [],
      rows: []
    }
  },
  methods: {
    setInitialData() {
      this.schema = [
        { label: 'Nombre llave', key: 'name_key' },
        { label: 'Descripción', key: 'description' },
        { label: 'Añadir', key: 'add_action', useSlot: true }
      ]
      
      this.rows = [
        {name_key: 'tracking_number', description: 'Numero de OT'},
        {name_key: 'client_name', description: 'Nombre cliente'}
      ]
    },
    addKeyword(value) {
      this.$emit('updateForm', value)
    }
  },
  mounted() {
    this.setInitialData()
  }
}
</script>
<style>
.hide-overflow-x {
  overflow-x: hidden !important;
  padding: 0 1rem;
}
</style>