<template>
    <div>
      <div class="d-flex align-items-center mt-customize justify-content-between">
          <div class="d-flex align-items-baseline">
            <h1 class="mr-customize mb-0 text-primary" v-if="!!showStep">{{ showStep }}</h1><span class="text-primary">{{ step.title }}</span>
          </div>
          <feather-icon
            v-if="showMaximizeIcon()"
            class="icon-hover text-primary"
            icon="Maximize2Icon"
            size="20"
            @click="openModalStep(step.id)"
          />
      </div>
      <hr class="mb-0 mt-customize">
      <modal-maximize 
        ref="modalMaximize" 
        :step="step" 
        :form="form" 
        @formStep="formStep" 
        @renderStep="renderStep" 
        :messageResponse="messageResponse" 
        :errorFields="errorFields"
        @changeStyleContainer="changeStyleContainer"/>
    </div>
</template>
<script>
import ModalMaximize from '../modal/ModalMaximize.vue'
export default {
  name: 'header-step',
  props: ['step', 'showStep', 'form', 'messageResponse', 'errorFields'],
  components: {ModalMaximize},
  methods: {
    showMaximizeIcon () {
      return [2, 4, 5].includes(this.step.id)
    },
    openModalStep (id) {
      this.$bvModal.show(`modalMaximize${id}`)
    },
    formStep (val) {
      this.$emit('formStep', val)
    },
    renderStep (val, step) {
      this.$emit('renderStep', val, step)
    },
    changeStyleContainer (val, tested) {
      this.$emit('changeStyleContainer', val, tested)
    }
  }
}
</script>