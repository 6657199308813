<template>
    <div class="mb-2">
      <form-render
        ref="formRenderStep1"
        :form.sync="formStep1"
        :fields="fieldsStep1"
        :key="keyFormRenderStep1"
        :disableAll="disabledAllFields || disabledSteps"
        />
    </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default {
  name: 'step1',
  props: ['editWebhook', 'form'],
  data () {
    return {
      formStep1: {},
      fieldsStep1: [],
      keyFormRenderStep1: 0,
      disabledAllFields: false,
      disabledSteps: false
    }
  },
  computed: {
    ...mapGetters({
      types_webhooks: 'getTypeWebhook'
    })
  },
  watch: {
    formStep1: {
      handler (val, oldVal) {
        this.$emit('formStep', val)
      },
      deep: true
    },
    types_webhooks () {
      this.setTypesWebhooks()
    },
    editWebhook (webhook) {
      const value = webhook?.webhook
      const isMKPOrSeller = value.marketplace || (value.organization_id !== value.shipper_id)
      const indexName = this.getPositionFindIndex(this.fieldsStep1, 'name', 'name_webhook')
      const indexOwner = this.getPositionFindIndex(this.fieldsStep1, 'name', 'owner')
      const indexType = this.getPositionFindIndex(this.fieldsStep1, 'name', 'types')
      this.fieldsStep1[indexName].validation = ''
      this.fieldsStep1[indexName].containerClass = value.id ? 'hide' : 'col-md-6 container-info'
      this.fieldsStep1[indexOwner].containerClass = isMKPOrSeller ? 'col-md-3 container-info' : 'hide'
      this.fieldsStep1[indexType].containerClass = value.id ? 'col-md-6 container-info' : 'col-md-3 container-info'
      this.formStep1.owner = value.owner.text
      this.disabledAllFields = value.disabledEdit
      this.disabledSteps = value.disabledSteps
      Vue.set(this.formStep1, 'types', this.form.types)
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fieldsStep1 = [
        {fieldType: 'FieldInput', name: 'name_webhook', label: 'Nombre Webhook', containerClass: 'col-md-6 container-info', placeholder: 'Ingrese nombre webhook', validation: 'required'},
        {fieldType: 'FieldInput', name: 'owner', label: 'Propietario', containerClass: 'hide', placeholder: 'Ingrese nombre propietario', disabled: true},
        {fieldType: 'FieldSelect', name: 'types', label: 'Tipo', options: this.types_webhooks, containerClass: 'col-md-3 container-info', validation: 'required'}
      ]
    },
    async validateStep () {
      return await this.$refs.formRenderStep1.onlyCheckForm()
    },
    setTypesWebhooks () {
      if (this.types_webhooks && !!this.types_webhooks.length) {
        this.setSelectOptions('types', { options: this.types_webhooks })
      }
    },
    setSelectOptions (name, { options }) {
      const index = this.getPositionFindIndex(this.fieldsStep1, 'name', name)
      if (index !== -1) {
        this.fieldsStep1[index].options = options
        this.keyFormRender++
      }
    },
    getPositionFindIndex (origin, type,  name) {
      return origin.findIndex(el => el[type] === name)
    }  
  }
}
</script>
<style>

</style>