<template>
    <div v-if="!formStep5.showTest" class="d-flex justify-content-center flex-column align-items-center">
      <div class="custom-title-step5 d-flex justify-content-center flex-column align-items-center mb-4">
        <feather-icon icon="AlertCircleIcon" size="70" class="mb-1"/>
        <span>Podrás habilitar el Webhook una vez ejecutada la prueba.</span>
      </div>
      <div class="d-flex flex-column">
        <span class="mb-1 font-weight-500">Por defecto realizaremos una prueba con datos de ejemplo.</span>
        <form-render
          ref="formRenderStep5"
          :form.sync="formStep5"
          :fields="fieldsStep5"
          :key="keyFormRenderStep5"
          formClass="align-items-start mb-2"
          :disableAll="disabledAllFields">
        </form-render>
      </div>
      <b-button :disabled="loading.test || disabledBtnTest || disabledAllFields || disabledBtnEdit" @click="testWebhook" class="mb-1" variant="warning">
        <b-spinner small v-if="loading.test"/>
        <span v-else>Probar</span>
      </b-button>
    </div>
    <div v-else class="container-response">
      <div class="pt-1 pb-1 pr-3 pl-3">
        <feather-icon
          :icon="formStep5.activatedChangeStatus ? 'CheckCircleIcon' : 'XCircleIcon'"
          size="15"
          :class="formStep5.activatedChangeStatus ? 'text-success' :'text-danger'"
        />
        <small v-if="formStep5.activatedChangeStatus">
          ¡Prueba exitosa! Funcionamiento Ok.
          Puede activar el Webhook. 
        </small>
        <small v-else>
          ¡Prueba Fallida! Error en los campos.
        </small>
      </div>
      <div class="textarea-custom">
        <b-form-textarea
          placeholder="JSON"
          :class="isModal ? 'height-custom-modal' : 'height-custom'"
          class="background-custom"
          v-model="formStep5.responseTest"
          disabled
        />
      </div>
      <div class="d-flex justify-content-center mt-2">
        <b-button @click="newTestWebhook" variant="warning" :class="isModal ? 'mb-1' : ''">
          <span>Nueva Prueba</span>
        </b-button>
    </div>
    </div>
</template>
<script>
import Vue from 'vue'
import BaseServices from '@/store/services/index'
export default {
  name: 'step5',
  props: ['editWebhook', 'form', 'isModal', 'setStep'],
  data () {
    return {
      loading: {
        test: false
      },
      formStep5: {
        responseTest: null,
        showTest: false,
        activatedChangeStatus: null
      },
      fieldsStep5: [],
      keyFormRenderStep5: 0,
      disabledAllFields: false,
      disabledBtnTest: false,
      baseService: new BaseServices(this),
      disabledBtnEdit: false
    }
  },
  watch: {
    editWebhook (webhook) {
      const value = webhook?.webhook
      this.disabledAllFields = value.disabledEdit
      this.disabledBtnEdit = !value.disabledSteps
    },
    'formStep5': {
      handler (val, oldVal) {
        if (!this.isModal) {
          this.emitFormStepEvent(val)
        }
      },
      deep: true
    },
    'form.showTest': {
      handler (val, oldVal) {
        this.formStep5.showTest = val
      },
      deep: true
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.setFields()
    },
    setFields () {
      this.fieldsStep5 = [
        {fieldType: 'FieldCheckbox', name: 'real_delivery', containerClass: 'col-12 container-info', options: [{id: false, text: 'Probar con datos reales de un envío'}], change: this.changeCheckbox}, 
        {fieldType: 'FieldInput', name: 'delivery', label: 'URL de envío para la prueba (opcional)', containerClass: 'hide', placeholder: 'Ingrese URL de envío para prueba', change: this.changeDelivery}
      ]
      if (!!this.isModal || !!this.setStep) {
        this.setInfoModal()
      }
    },
    setInfoModal () {
      this.disabledAllFields = this.form.disabledAllFields
      this.disabledBtnEdit = !this.form.disabledSteps

      this.formStep5.real_delivery = this.form.real_delivery
      this.changeCheckbox('real_delivery', this.form.real_delivery)
      this.formStep5.delivery = this.form.delivery
      this.disabledBtnTest = this.formStep5.real_delivery && !this.formStep5.delivery
      this.formStep5.responseTest = this.form.responseTest
      this.formStep5.showTest = this.form.showTest
      this.formStep5.activatedChangeStatus = this.form.activatedChangeStatus
    },
    changeCheckbox (name, value) {
      Vue.set(this.formStep5, 'delivery', null)
      const indexDelivery = this.getPositionFindIndex(this.fieldsStep5, 'name', 'delivery')
      this.fieldsStep5[indexDelivery].containerClass = !!value ? 'col-md-12 container-info' : 'hide'
      this.disabledBtnTest = value && !this.formStep5.delivery
      this.keyFormRenderStep5++
      if (!this.isModal) this.emitFormStepEvent(this.formStep5)
    },
    changeDelivery (name, value) {
      this.disabledBtnTest = !value
    },
    getPositionFindIndex (origin, type,  name) {
      return origin.findIndex(el => el[type] === name)
    }, 
    emitFormStepEvent(val) {
      this.$emit('formStep', val)
    },
    testWebhook () {
      this.loading.test = true
      const params = {}
      const queryParams = {
        webhook_id: this.form.id
      }
      if (this.formStep5.delivery) queryParams.url = this.formStep5.delivery
      return this.baseService.callService('testWebhook', queryParams, params, { fullResponseError: true })
        .then(resp => {
          const response = {
            ...resp.response?.data,
            status: resp.response?.data?.status === null ? 500 : resp.response?.data?.status
          }
          this.formStep5.responseTest = JSON.stringify(response, null, 2)
          this.formStep5.showTest = true
          this.formStep5.activatedChangeStatus = response?.status >= 200 && response?.status < 300
          this.$emit('changeStyleContainer', this.formStep5.showTest, this.formStep5.activatedChangeStatus)
          this.emitFormStepEvent(this.formStep5)
          if (!!this.isModal) {
            this.$emit('renderStep', true, 5)
          }  
        })
        .catch(err => {
          let errorMessage = err
          if (typeof err === 'object') {
            errorMessage = err?.response || err?.error || err?.message || 'Error'
          }
          this.$alert(this.$t('msg-problema-prueba-webhook', {code: errorMessage}))
        })
        .finally(end => {
          this.loading.test = false
        })
    },
    newTestWebhook () {
      this.formStep5.showTest = false
      this.changeCheckbox('real_delivery', this.formStep5.showTest)
      Vue.set(this.formStep5, 'real_delivery', false)
      this.$emit('changeStyleContainer', this.formStep5.showTest, this.formStep5.activatedChangeStatus)
      this.emitFormStepEvent(this.formStep5)
      if (!!this.isModal) {
        this.$emit('renderStep', true, 5)
      }  
    }
  }
}
</script>
<style>
.custom-title-step5 {
  color: #1B4284;
}
.custom-title-step5 span {
  text-align: center;
  color: #1B4284;
  font-weight: 500;
  font-size: 15px;
}
textarea.form-control.height-custom{
  height: 100%;
}
textarea.form-control.height-custom-modal{
  height: 50vh;
}
.form-control.background-custom:disabled {
  background-color: #fff;
}
.font-weight-500 {
  font-weight: 500;
}
#real_delivery label {
  font-weight: 300;
}
.textarea-custom{
  padding: 0 1rem;
  width: 100%;
  height: 100%;
}
.container-response {
  display: flex;
  flex-direction: column;
  height: 95%;
}
@media screen and (max-width: 767px){
  .container-response {
    padding: 1rem;
  }
  textarea.form-control.height-custom{
    height: 50vh;
  }
  }
</style>