<template>
  <b-modal id="modalTestNotify" size="sm" :title="$t('Notificación de prueba')" no-close-on-backdrop hide-footer @close="close">
    <form-render :fields="fields" containerButtonsClass="col-sm-12" :form.sync="form" ref="formTestNotify" :key="keyFormTestNotify" />
    <b-button class="mt-2 push-right" @click="testNotify" variant="success" :disabled="loading.test"><b-spinner v-show="loading.test" small></b-spinner> Enviar prueba</b-button>
  </b-modal>
</template>
<script>
export default {
  name: 'modalTestNotify',
  props: ['onClose'],
  data() {
    return {
      fields: [],
      keyFormTestNotify: 0,
      form: {},
      statuses: [
        { id: 5, text: 'Listo para despacho' },
        { id: 6, text: 'En planta origen' },
        { id: 9, text: 'En reparto' },
        { id: 10, text: 'Entregado' },
        { id: 11, text: 'Disponible para retiro' },
        { id: 19, text: 'Envío no pudo ser entregado - No hay quien reciba' },
        { id: 26, text: 'Listo para despacho impreso' }
      ],
      loading: {
        test: false
      }
    }
  },
  methods: {
    async testNotify() {
      const valid = await this.$refs.formTestNotify.onlyCheckForm()
      if (!valid) return
      this.loading.test = true
      const queryParams = { email: this.form.email, status: this.form.status.id }
      const params = { company_id: this.$route.params?.id }
      this.sendTestNotify(params, queryParams)
    },
    sendTestNotify(params, queryParams) {
      this.$store.dispatch('fetchService', {
        name: 'testNotification',
        params,
        queryParams,
        onSuccess: () => {
          this.$bvToast.toast('Notificaciones enviada', {
            title: this.$t('Operación realizada con Exito'),
            variant: 'success',
            solid: true
          })
          this.loading.test = false
          this.close('modalTestNotify')
        },
        onError: (e) => {
          console.error(e)
          this.loading.test = false
        }
      })
    },
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldInput', type: 'email', name: 'email', label: this.$t('Email destinatario'), containerClass: 'col-12 container-info', placeholder: 'Email', validation: 'required' },
        {
          fieldType: 'FieldSelect', name: 'status', label: this.$t('Estado'), containerClass: 'col-12 container-info', placeholder: 'Seleccione estado', options: this.statuses, validation: 'required'
        }
      ]
    },
    cleanForm() {
      this.form = {}
    },
    close() {
      this.$emit('onClose', 'modalTestNotify')
      this.cleanForm()
    }
  },
  mounted() {
    this.setInitialData()
  }
}
</script>
<style>
.custom-form {
  display: flex;
}
</style>