<template>
  <div class="mb-2">
    <div v-if="loading.statusAll" class="d-flex justify-content-center">
      <b-spinner variant="primary"/>
    </div>
    <div v-else>
      <form-render
        ref="formRenderStep2"
        :form.sync="formStep2"
        :fields="fieldsStep2"
        :key="keyFormRenderStep2"
        formClass="justify-content-center"
        :disableAll="disabledAllFields || disabledSteps">
        <template #status>
          <div v-if="formStep2.statusAll.length > 0 && isModal">
            <div class="grid-status">
              <div class="not-selected m-0-5">
                <h5 class="mb-1 m-0-5">{{ $t('No Seleccionados') }}</h5>
                <div class="badge-container">
                  <span v-for="(state, index) in getFilteredStatusList()" v-show="!state.saved" :key="`saved_${index}`" class="m-0-5 badge-cursor-pointer">
                    <b-badge
                      :variant="disabledAllFields || disabledSteps ? 'outlined-primary-disabled' : 'outlined-primary'"
                      @click="selectStatus(state, CONDITIONS.SELECTED)" class="mb-1">{{
                        state.name }}</b-badge>
                  </span>
                </div> 
              </div>
              <div class="selected m-0-5 ml-2">
                <h5 class="mb-1 m-0-5">{{ $t('Seleccionados') }}</h5>
                <b-form-checkbox
                  :disabled="disabledAllFields || disabledSteps"
                  v-if="!searchStateByName"
                  class="m-0-5"
                  v-model="selectedAll"
                  :indeterminate="indeterminate"
                  @change="() => selectAll(selectedAll)"
                >
                {{ $t('Marcar todos los estados') }}
                </b-form-checkbox>
                <div class="badge-container">
                  <span v-for="(state, index) in getFilteredStatusList()" v-show="state.saved" :key="`saved_${index}`" class="m-0-5 badge-cursor-pointer">
                    <b-badge
                      :variant="disabledAllFields || disabledSteps ? 'primary outlined-completed-disabled' : 'primary outlined-defaul'"
                      @click="selectStatus(state, CONDITIONS.NOT_SELECTED)" class="mb-1">{{
                        state.name }}</b-badge>
                  </span>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center" v-if="filteredStatuses.length === 0 && searchStateByName">
              <span>No se encontraron registros para mostrar</span>
            </div>
            <div class="d-flex justify-content-end mt-1" v-if="showBtnSaved">
              <b-button @click="savedStatus()" variant="warning">
                <span>Guardar cambios</span>
              </b-button>
            </div>
          </div>
          <div v-if="formStep2.statusAll.length > 0 && !isModal">
              <span v-for="(state, index) in formStep2.statusAll" v-show="filterMainStates(state)" :key="`saved_${index}`" class="m-0-5 badge-cursor-pointer">
                <b-badge
                  v-if="!disabledAllFields && !disabledSteps"
                  :variant="state.saved ? 'primary outlined-default' : 'outlined-primary'"
                  @click="selectStatus(state, CONDITIONS.FILTER)" class="mb-1">{{
                    state.name }}</b-badge>
                <b-badge
                  v-else
                  :variant="state.saved ? 'primary outlined-completed-disabled' : 'outlined-primary-disabled'"
                   class="mb-1">
                   {{state.name }}
                </b-badge>
              </span>
              <div class="row m-0-5 flex-column">
                <span class="text-primary weight-500 d-flex align-items-center"><span class="marker"></span>Seleccione expandir para ver más estados.</span>
                <span class="text-primary weight-500" v-if="showCountStatus">Total {{countStatus}} marcados.</span>
                <span v-if="errorMessage" class="text-danger">Debe seleccionar al menos un estado.</span>
              </div>
          </div>
          <div v-if="formStep2.statusAll.length === 0" class="d-flex justify-content-center">
            <span>Seleccione Tipo de estado en el paso 1</span>
          </div>
        </template>
      </form-render>
    </div>
  </div>
</template>
<script>
import BaseServices from '@/store/services/index'
export default {
  name: 'step2',
  props: ['form', 'isModal', 'editWebhook'],
  data () {
    return {
      formStep2: {
        statusAll: [],
        counterStatus: {}
      },
      fieldsStep2: [],
      keyFormRenderStep2: 0,
      CONDITIONS: Object.freeze({
        FILTER: 'filter',
        SELECTED: 'selected',
        NOT_SELECTED: 'not-selected'
      }),
      indeterminate: false,
      selectedAll: false,
      showBtnSaved: false,
      countStatus: 0,
      showCountStatus: false,
      searchStateByName: false,
      filteredStatuses: [],
      statusAll: [],
      filterStatusMain: [],
      errorMessage: false,
      baseService: new BaseServices(this),
      loading: {
        statusAll: false
      },
      disabledAllFields: false,
      disabledSteps: false
    }
  },
  watch: {
    'formStep2': {
      handler (val, oldVal) {
        if (!this.isModal) {
          this.emitFormStepEvent(val)
        }
      },
      deep: true
    },
    'form.statusAll': {
      handler (val, oldVal) {
        this.updateFormStatusAll(val)
      },
      deep: true
    },
    'form.counterStatus': {
      handler (val, oldVal) {
        this.updateCounterStatus(val)
      },
      deep: true
    },
    'form.types': {
      handler (val, oldVal) {
        if (val !== oldVal) this.getStatesToType(val)
      },
      deep: true
    },
    'formStep2.statusAll': {
      handler (val, oldVal) {
        if (this.isModal) {
          this.updateModalStatusActions(val)
        }
        this.counterStatus()
      },
      deep: true
    },
    editWebhook (webhook) {
      const value = webhook?.webhook
      this.disabledAllFields = value.disabledEdit
      this.formStep2.disabledAllFields = this.disabledAllFields
      this.disabledSteps = value.disabledSteps
      this.formStep2.disabledSteps = this.disabledSteps
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      if (!!this.isModal) {
        this.disabledAllFields = this.form.disabledAllFields
        this.disabledSteps = this.form.disabledSteps

        this.formStep2.statusAll = this.form.statusAll || []
        this.originalStatusAll = this.form.statusAll || []
      }
      this.fieldsStep2 = [
        { fieldType: 'FieldInput', name: 'search_state', placeholder:'Ingrese un estado', containerClass: this.formStep2.statusAll.length > 0 && this.isModal ? 'col-md-6 container-info mt-1' : 'hide', icon: 'SearchIcon', change: this.filterStatusByInput, clearable:true},
        { name: 'status', useSlot: true, containerClass: 'col-sm-12 col-md-12' }
      ]
    },
    getStatesToType (val) {
      if (!!val?.id) {
        this.loading.statusAll = true
        const params = {}
        const queryParams = {
          type: val.resource_type
        }
        this.$store.dispatch('fetchService', { name: 'getStatusWebhook', params, queryParams, onSuccess: (resp) => this.onSuccessGetStatus(resp, val), onError: (err) => this.onErrorGetStatus(err)})   
      } else {
        this.formStep2.statusAll = []
      }
    },
    onSuccessGetStatus (resp, val) {
      this.formStep2.statusAll = resp.response.data.statuses.map(item => {
        return {
          ...item,
          saved: false
        }
      })
      this.filterStatusMain = resp.response.data.main_statuses
      this.loading.statusAll = false
      // Seteamos los valores obtenidos en la edición
      if (val?.setObtainedStates && this.form.filters) {
        const statusAll = this.formStep2.statusAll.map(status => {
          return {
            ...status,
            saved: this.form.filters.includes(status.id)
          }
        })
        this.formStep2.statusAll = statusAll
      }
    },
    onErrorGetStatus (err) {
      console.error(err)
      this.loading.statusAll = false
    },
    filterMainStates (state) {
      return this.filterStatusMain.includes(state.id)
    },
    selectStatus(state, type) {
      if (!this.disabledAllFields && !this.disabledSteps) {
        this.formStep2.statusAll = this.formStep2.statusAll.map(item => {
          if (item.id === state.id) return {...item, saved: type === this.CONDITIONS.FILTER ? !item.saved : type === this.CONDITIONS.SELECTED}
          return item
        })
        if (this.searchStateByName) {
          this.filteredStatuses = this.filteredStatuses.map(item => {
            if (item.id === state.id) return {...item, saved: type === this.CONDITIONS.SELECTED}
            return item
          })
        }
        this.errorMessage = false
      }
    },
    selectAll (value) {
      this.selectedAll = value
      this.formStep2.statusAll = this.formStep2.statusAll.map(item => {
        return {
          ...item,
          saved: value
        }
      })
      this.errorMessage = false
    },
    differencesExist(updatedArray, originalArray) {
      const differences = []
      updatedArray.forEach(updateObject => {
        const originalObject = originalArray.find(o => o.id === updateObject.id)
        if (!originalObject || JSON.stringify(updateObject) !== JSON.stringify(originalObject)) {
          differences.push(updateObject)
        }
      })
      originalArray.forEach(originalObject => {
        const updateObject = updatedArray.find(o => o.id === originalObject.id)
        if (!updateObject) {
          differences.push(originalObject)
        }
      })
      return differences.length > 0
    },
    savedStatus () {
      this.counterStatus()
      this.emitFormStepEvent(this.formStep2)
      this.$emit('hideModal', 2) 
    },
    counterStatus () {
      this.countStatus = this.formStep2.statusAll.filter(item => !!item.saved).length
      this.showCountStatus = this.countStatus > 0
      this.formStep2.counterStatus = {
        counter: this.countStatus,
        isShow: this.showCountStatus
      }
    },
    filterStatusByInput (name, value) {
      this.searchStateByName = !!value.trim()
      if (!value.trim()) {
        this.filteredStatuses = this.statusAll
        return
      }
      const normalizedInput = this.removeAccents(value.trim())
      const filteredArray = this.statusAll.filter(item => {
        const normalizedStatusName = this.removeAccents(item.name)
        return normalizedStatusName.includes(normalizedInput)
      })
      this.filteredStatuses = filteredArray
    },
    removeAccents (value) {
      return value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    getFilteredStatusList() {
      return this.searchStateByName ? this.filteredStatuses : this.formStep2.statusAll
    },
    emitFormStepEvent(val) {
      this.$emit('formStep', val)
    },
    updateFormStatusAll(val) {
      this.formStep2.statusAll = val || []
    },
    updateCounterStatus(val) {
      this.showCountStatus = val?.isShow || false
      this.countStatus = val?.counter || 0
    },
    updateModalStatusActions(val) {
      const savedValues = val.map(el => el.saved)
      this.indeterminate = !savedValues.every(saved => saved === savedValues[0])
      this.selectedAll = !this.indeterminate && savedValues.every(saved => !!saved)
      this.showBtnSaved = this.differencesExist(val, this.originalStatusAll)
      this.statusAll = val
    },
    validateStep () {
      this.errorMessage = !this.formStep2.statusAll.some(el => !!el.saved)
      return !this.errorMessage
    }
  }
}
</script>
<style>
.m-0-5 {
  margin: 0.5rem;
}
.badge {
  padding: 0.5rem 0.5rem;
  border-radius: 1rem;
}
span.badge.badge-outlined-primary {
  border: 1px solid #064389;
  color: #064389;
}
span.badge.badge-outlined-primary-disabled {
  border: 1px solid #6D6B7A;
  color: #6D6B7A;
}
.outlined-completed-disabled {
  border: 1px solid #6D6B7A;
  color: #fff;
  background: #6D6B7A;
}

span.badge.badge-outlined-default {
  border: 1px solid #d3d4d6;
  color: #d3d4d6;
}
li::marker {
  display: inline-block;
  margin-right: 5px;
}
.weight-500 {
  font-weight: 500;
}
.marker {
  display: block;
  background: #044389;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 4px;
}
.badge-cursor-pointer:hover {
  cursor: pointer;
}
.grid-status {
  display: grid;
  grid-template-columns: 50% 50%;
}
.hide {
  display: none;
}
.badge-container {
  overflow-y: auto; 
  max-height: 600px;
}
.badge-container::-webkit-scrollbar {
    width: 6px;
}
.badge-container::-webkit-scrollbar-thumb {
    background-color: #686B6C;
    border-radius: 5px;
}
#search_state input {
  border-right: none;
}
#search_state .input-group-text {
  border-left: none;
}
#search_state svg {
  width: 18px;
  height: 18px;
  stroke: #686B6C;
}
</style>