<template>
    <div class="mb-2">
      <form-render
        ref="formRenderStep3"
        :form.sync="formStep3"
        :fields="fieldsStep3"
        :key="keyFormRenderStep3"
        formClass="align-items-center"
        :disableAll="disabledAllFields || disabledSteps">
        <template #tooltip>
          <feather-icon
            v-b-tooltip.hover :title="$t('Para campo dinámico coloque código de texto entre símbolos: < >')"
            class="mt-customize"
            icon="AlertCircleIcon"
            size="24"
          />
        </template>
        <template #error>
          <small class="text-danger">La url tiene algun valor dinámico incorrecto</small>
        </template>
      </form-render>
    </div>
</template>
<script>
import Vue from 'vue'
export default {
  name: 'step3',
  props: ['editWebhook', 'form', 'errorURL'],
  data () {
    return {
      formStep3: {},
      fieldsStep3: [],
      keyFormRenderStep3: 0,
      optionsSelectMethods: [
        {id: 'POST', text: 'POST'},
        {id: 'GET', text: 'GET'},
        {id: 'PUT', text: 'PUT'},
        {id: 'DELETE', text: 'DELETE'}
      ],
      disabledAllFields: false,
      disabledSteps: false
    }
  },
  watch: {
    formStep3: {
      handler (val, oldVal) {
        this.$emit('formStep', val)
      },
      deep: true
    },
    editWebhook (webhook) {
      const value = webhook?.webhook
      const indexEndpoint = this.getPositionFindIndex(this.fieldsStep3, 'name', 'endpoint')
      this.fieldsStep3[indexEndpoint].containerClass = value.id ? 'col-md-11 container-info' : 'col-md-7 container-info'
      this.disabledAllFields = value.disabledEdit
      this.disabledSteps = value.disabledSteps
      Vue.set(this.formStep3, 'endpoint', this.form.endpoint)
      Vue.set(this.formStep3, 'methods', this.form.methods)
    },
    errorURL (value) {
      const indexError = this.getPositionFindIndex(this.fieldsStep3, 'name', 'error')
      this.fieldsStep3[indexError].containerClass = value ? 'col-md-12' : 'hide'
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fieldsStep3 = [
        {fieldType: 'FieldInput', name: 'endpoint', label: 'Endpoint', containerClass: 'col-md-7 container-info', placeholder: 'Ingrese endpoint del webhook', validation: 'required'},
        {name: 'tooltip', useSlot: true, containerClass: 'col-md-1' },
        {fieldType: 'FieldSelect', name: 'methods', label: 'Métodos', containerClass: 'col-md-3 container-info', options: this.optionsSelectMethods,  validation: 'required'},
        {name: 'error', useSlot: true, containerClass: this.errorURL ? 'col-md-12' : 'hide' }
      ]
      if (!this.editWebhook) {
        Vue.set(this.formStep3, 'methods', this.optionsSelectMethods[0])
      }
    },
    async validateStep () {
      return await this.$refs.formRenderStep3.onlyCheckForm()
    },
    getPositionFindIndex (origin, type,  name) {
      return origin.findIndex(el => el[type] === name)
    }
  }
}
</script>