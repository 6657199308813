<template>
    <b-modal 
      :id="`modalMaximize${step.id}`" 
      modal-class="dialog-1600" 
      no-close-on-esc
      no-close-on-backdrop
      centered
      hide-footer 
      @close="close"
    >
      <template #modal-header>
        <div class="d-flex align-items-center container-header-modal">
          <h5 class="modal-title">{{step.title}}</h5>
          <feather-icon
            class="icon-hover push-right text-primary"
            icon="Minimize2Icon"
            size="20"
            @click="hideModal(step.id)"
          />
        </div>
      </template>
      <component 
        :is="`Step${step.id}`" 
        class="mt-1" 
        :form="form" 
        :ref="`formRenderStep${step.id}`" 
        :isModal="true" 
        @formStep="formStep" 
        @hideModal="hideModal" 
        @renderStep="renderStep" 
        :messageResponse="messageResponse" 
        :errorFields="errorFields"
        @invalidJSON="invalidJSON"
        @changeStyleContainer="changeStyleContainer"/>
        <div class="mb-1 container-message" v-if="step.id === 4 && (messageResponse || showErrorJSON)">
          <div v-if="messageResponse && !showErrorJSON">
            <feather-icon
              :icon="messageResponse.code === 200 ? 'CheckCircleIcon' : 'XCircleIcon'"
              size="15"
              :class="messageResponse.code === 200 ? 'text-success' :'text-danger'" 
              class="mr-customize"
            />
            <span>{{ messageResponse.message }}</span>
          </div>
          <div v-if="showErrorJSON">
            <feather-icon
              icon="XCircleIcon"
              size="15"
              class="text-danger mr-customize"
            />
            <span>¡JSON inválido! Por favor verifique los campos.</span>
          </div>
        </div>
    </b-modal>
</template>
  
<script>
import Step1 from '../step/Step1.vue'
import Step2 from '../step/Step2.vue'
import Step3 from '../step/Step3.vue'
import Step4 from '../step/Step4.vue'
import Step5 from '../step/Step5.vue'
export default {
  name: 'modalMaximize',
  props: ['step', 'form', 'messageResponse', 'errorFields'],
  components: { Step1, Step2, Step3, Step4, Step5 },
  data() {
    return {
      showErrorJSON: null
    }
  },
  methods: {
    close() {
      this.$emit('update:form', {})
    },
    hideModal (id) {
      this.close()
      this.$bvModal.hide(`modalMaximize${id}`)
    },
    formStep (val) {
      this.$emit('formStep', val)
    },
    renderStep (val, step) {
      this.$emit('renderStep', val, step)
    },
    invalidJSON (val) {
      this.showErrorJSON = val
    },
    changeStyleContainer (val, tested) {
      this.$emit('changeStyleContainer', val, tested)
    }
  }

}
</script>
<style>
.container-header-modal{
  width: 100%;
}
</style>